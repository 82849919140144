<template lang="pug">
  .v-btn-group
    slot
</template>

<script>
export default {
  name: 'VBtnGroup'
}
</script>

<style lang="scss">
  .v-btn-group {
    display: flex;

    & > button {
      &:not(:first-child) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }

      &:not(:last-child) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-right: 1px solid rgba(255, 255, 255, .5);
      }
    }

    & > * {
      &:not(:first-child) {
        button {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }

      &:not(:last-child) {
        button {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-right: 1px solid rgba(255, 255, 255, .5);
        }
      }
    }
  }
</style>

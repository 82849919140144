import api from '@/api'

export default {
  state: {
    doneProducts: []
  },

  mutations: {
    SET_DONE_PRODUCTS (state, doneProducts) {
      state.doneProducts = doneProducts
    }
  },

  actions: {
    fetchDoneProducts ({ commit }, params = {}) {
      return api.products.list(params)
        .then(({ data }) => {
          commit('SET_DONE_PRODUCTS', data.result.data)
          return data.result
        })
        .catch((error) => {
          console.error(error)
        })
    }
  },

  getters: {
    doneProducts (state) {
      return state.doneProducts
    }
  }
}

import roles from '@/util/roles'

export const formateDate = (date) => {
  if (date !== null) {
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const daystr = day < 10 ? `0${day}` : day
    const monthstr = month < 10 ? `0${month}` : month
    return `${year}-${monthstr}-${daystr}`
  }
  return date
}

export const formateDateReverse = (date) => {
  return formateDate(date).split('-').reverse().join('-')
}

export const formateDateTime = (date) => {
  return `${date.substring(0, 10)} / ${date.substring(11, 19)}`
}

export const percentReducer = function (accumulator, value) {
  // Set default value (0) as clear
  let clearValue = value.percent
  if (typeof value.percent !== 'number') {
    // If percent is not empty string get substring or set 0 when percent is empty
    clearValue = value && value.percent.length > 0 ? ingredientPercentToFixedFloat(value) : '0'
  }
  // Parse string percent as float and sum
  const res = accumulator + parseFloat(clearValue)

  // Fix "0.1 + 0.2" problem by set fraction limit and convert res to float
  return parseFloat(res.toFixed(3))
}

export const ingredientPercentToFixedFloat = function (ingredient) {
  // Set float number part length by check ingredient autoload property
  const numLength = ingredient.autoload ? 3 : 2
  const percent = String(ingredient.percent)
  let intPart = String(ingredient.percent)
  const dotIndex = percent.indexOf('.')

  let floatPart = ''
  if (dotIndex !== -1) {
    intPart = percent.substring(0, dotIndex)
    floatPart = percent.substring(dotIndex + 1, percent.length)
  }

  return intPart + '.' + floatPart.substring(0, numLength)
}

export const addQueryParams = (url, params = {}) => {
  let paramsLine = ''
  for (const key in params) {
    if (params[key] !== null) {
      if (Array.isArray(params[key])) {
        if (params[key].length) {
          paramsLine += paramsLine ? '&' : '?'
          paramsLine += `${key}=${params[key].join(',')}`
        }
      } else {
        paramsLine += paramsLine ? '&' : '?'
        paramsLine += key + '=' + encodeURIComponent(params[key])
      }
    }
  }
  return url + paramsLine
}

export const windowResizeObserver = (function () {
  const observers = []
  return {
    init () {
      window.onresize = () => {
        for (const item of observers) {
          if (typeof item === 'function') {
            item()
          }
        }
      }
    },
    subscribe (obs) {
      if (!window.onresize) {
        this.init()
      }
      if (typeof obs === 'function') {
        observers.push(obs)
      }
    },
    unsubscribe (obs) {
      const index = observers.indexOf(obs)
      if (index !== -1) {
        observers.splice(index, 1)
      }
    }
  }
}())

export const getRouteRedirect = (role, mainRoute) => {
  const redirectRules = [
    {
      from: '/farm',
      to: '/farm/planning',
      allow: [roles.admin, roles.support, roles.consultant, roles.customer, roles.director, roles.technologist]
    },
    {
      from: '/farm',
      to: '/farm/reports',
      allow: [roles.admin, roles.support, roles.consultant, roles.accounter]
    },
    {
      from: '/farm/storage',
      to: '/farm/storage/ingredients',
      allow: [roles.admin, roles.support, roles.consultant, roles.customer, roles.director, roles.technologist]
    },
    {
      from: '/farm/storage',
      to: '/farm/storage/supplies',
      allow: [roles.admin, roles.support, roles.consultant, roles.accounter]
    },
    {
      from: '/farm/settings',
      to: '/farm/settings/employees',
      allow: [roles.admin, roles.support, roles.consultant, roles.customer, roles.director]
    },
    {
      from: '/farm/settings',
      to: '/farm/settings/mixer',
      allow: [roles.admin, roles.support, roles.consultant, roles.technologist]
    }
  ]
  return redirectRules.find(item => item.from === mainRoute && item.allow.includes(role))?.to
}

<template lang="pug">
  li.v-multiselect__options-item(
    :class="{ 'v-multiselect__options-item--selected': isSelected }"
    @click="onClick") {{ item[itemName] }}

</template>

<script>
export default {
  name: 'VMultiselectItem',

  props: {
    selected: Array,
    item: Object,
    itemName: String,
    itemValue: String
  },

  methods: {
    onClick () {
      const item = this.itemValue
        ? this.item[this.itemValue]
        : this.item
      if (this.isSelected) {
        this.$emit('unselect', item)
      } else {
        this.$emit('select', item)
      }
    }
  },

  computed: {
    isSelected () {
      return this.selected
        .some(item => this.itemValue
          ? item === this.item[this.itemValue]
          : item === this.item)
    }
  }
}
</script>

<style lang="scss">
</style>

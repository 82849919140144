import api from '@/api'

export default {
  state: {
    recipesList: [],
    groupsList: []
  },

  mutations: {
    SET_INGREDIENTS_LIST (state, ingredients) {
      state.ingredientsList = ingredients
    },

    SET_GROUPS_LIST (state, groups) {
      state.groupsList = groups
    }
  },

  actions: {

    fetchGroupsList ({ commit, rootState }) {
      return api.lists.getGroups(rootState.auth.current_farm)
        .then(({ data }) => {
          commit('SET_GROUPS_LIST', data.result)
          return data.result
        })
        .catch((error) => {
          console.error(error)
        })
    }
  },

  getters: {
    groupsList (state) {
      return state.groupsList
    }
  }
}

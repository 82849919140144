<template lang="pug" functional>
  font-awesome-icon.v-icon(
    :style="{ fontSize: `${props.fontSize}em`, color: `${props.color}` }"
    :class="{ 'v-icon--position-right': props.positionRight, 'v-icon--position-left': props.positionLeft }"
    :size="props.size"
    :icon="props.icon")
</template>

<script>
export default {
  name: 'VIcon',

  props: {
    size: {
      type: String,
      default: 'sm'
    },

    color: {
      type: String,
      default: ''
    },

    icon: {
      type: String,
      default: ''
    },

    fontSize: {
      type: String,
      default: ''
    },

    positionRight: {
      type: Boolean,
      default: false
    },

    positionLeft: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
  $offset: 1.3rem;

  .v-icon {
    &--position {

      &-right {
        margin-left: $offset;
      }

      &-left {
        margin-right: $offset;
      }
    }
  }
</style>

import Vue from 'vue'

Vue.filter('date', (date) => {
  return date
    ? `${date.substring(0, 10)}`
    : '-'
})

Vue.filter('time', (date) => {
  return date
    ? `${date.substring(11, 16)}`
    : '-'
})

Vue.filter('datetime', (date) => {
  return date
    ? `${date.substring(0, 10)} / ${date.substring(11, 16)}`
    : '-'
})

Vue.filter('rounding', (number) => {
  return Math.round(number * 100) / 100
})

Vue.filter('srtstr', function (value) {
  return value.length > 45
    ? `${value.substring(0, 45)}...`
    : value
})

Vue.filter('liststr', function (value, field = 'name') {
  let result = ''
  for (const item of value) {
    result += field
      ? `${item[field]}, `
      : `${item}, `
  }
  return result.substring(0, result.length - 2)
})

Vue.filter('sectomin', (value) => {
  if (value === null) {
    return 0
  }
  return Math.floor(value / 6) / 10
})

Vue.filter('sectotime', (value) => {
  const ss = Math.floor(value % 60)
  const mm = Math.floor(value / 60 % 60)
  const hh = Math.floor(value / 60 / 60 % 60)
  const ssStr = ss < 10 ? `0${ss}` : ss
  const mmStr = mm < 10 ? `0${mm}` : mm
  const hhStr = hh < 10 ? `0${hh}` : hh
  return `${hhStr}:${mmStr}:${ssStr}`
})

Vue.filter('toPrice', (value) => {
  if (typeof value !== 'number') {
    return value
  }
  return +value.toFixed(2)
})

Vue.filter('toFixed', (value, num = 0) => {
  if (typeof value !== 'number') {
    return value
  }
  return +value.toFixed(num)
})

import { render, staticRenderFns } from "./VLoader.vue?vue&type=template&id=859b8068&scoped=true&lang=pug&functional=true"
import script from "./VLoader.vue?vue&type=script&lang=js"
export * from "./VLoader.vue?vue&type=script&lang=js"
import style0 from "./VLoader.vue?vue&type=style&index=0&id=859b8068&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "859b8068",
  null
  
)

export default component.exports
<template lang="pug">
  .v-mobile-tabs
    .tabs
      ul.tabs__list
        li.tabs__item(
          v-for="(tab, index) in visibleItems"
          :key="index"
          :class="{ 'tabs__item--active': $route.path === tab.path }")
          router-link.tabs__link(
            :to="tab.path") {{ tab.title | translate }}

    v-dropdown(
      auto-close
      width-auto
      position-right)
      template(v-slot="{ toggled }")
        v-btn(icon)
          font-awesome-icon(
            size="sm"
            icon="chevron-down"
            :style="{ transform: `rotate(${toggled ? 180 : 0}deg)` }")

      v-card(
        elevated
        slot="content")
        .v-mobile-tabs__dropdown-list
          .v-mobile-tabs__dropdown-item(
            v-for="(item, index) in dropdownItems"
            :key="index"
            @click="$router.push(item.path)") {{ item.title | translate }}

</template>

<script>
export default {
  name: 'VMobileTabs',

  props: {
    options: Array
  },

  computed: {
    selectedIndex () {
      return this.options
        .findIndex(item => item.path === this.$route.path)
    },

    visibleItems () {
      return [this.options[this.selectedIndex]]
    },

    // visibleItems () {
    //   if (this.selectedIndex > 0 && this.selectedIndex < this.options.length - 2) {
    //     return [this.options[this.selectedIndex - 1], this.options[this.selectedIndex], this.options[this.selectedIndex + 1]]
    //   } else if (this.selectedIndex > 0) {
    //     return [this.options[this.options.length - 3], this.options[this.options.length - 2], this.options[this.options.length - 1]]
    //   }
    //   return [this.options[this.selectedIndex], this.options[this.selectedIndex + 1], this.options[this.selectedIndex + 2]]
    // },

    dropdownItems () {
      return this.options
        .filter(item => !this.visibleItems
          .some(el => el === item))
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .v-mobile-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: .5rem;
    margin-bottom: 1rem;
    background-color: $color-white;

    .tabs__item {
      margin-right: 2rem;
    }

    &__dropdown-list {
      padding: .8rem 0;
    }

    &__dropdown-item {
      min-width: 22rem;
      padding: 1rem 2.6rem;
      font-size: 1.6rem;
      cursor: pointer;

      &:hover {
        background-color: $table-row-bg;
      }
    }
  }
</style>

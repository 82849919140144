<template lang="pug" functional>
  .v-card__title
    slot
</template>

<script>
export default {
  name: 'VCardTitle'
}
</script>

<style lang="scss" scoped>
</style>

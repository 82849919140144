<template lang="pug">
  .tabs-layout
    .tabs
      ul.tabs__list
        li(
            v-for="(tab, index) in filteredOptions"
            :key="index"
            :class="{ 'tabs__item--active': $route.path === tab.path }"
          ).tabs__item(v-if="!tab.disabled")
          router-link(:to="tab.path").tabs__link {{ tab.title | translate }}

</template>

<script>
export default {
  name: 'VNavTabs',

  props: {
    options: Array
  },

  data: () => ({
    hiddenTabs: {
      accountant: [
        '/farm/storage/ingredients',
        '/farm/storage/supplies',
        '/farm/storage/expense',
        '/farm/storage/suppliers'
      ]
    }
  }),

  computed: {
    filteredOptions () {
      const hiddenTabs = this.$data['hiddenTabs'][this.$store.state.auth.user.user.role]
      if (hiddenTabs === undefined) {
        return this.$props.options
      }

      return this.$props.options.filter(item => !hiddenTabs.includes(item.path))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

import api from '@/api'

export default {
  state: {
    timetables: []
  },

  getters: {
    timetables (state) {
      return state.timetables
    }
  },

  mutations: {
    SET_TIMETABLES (state, timetables) {
      state.timetables = timetables
    },

    ADD_TIMETABLE (state, data) {
      state.timetables.unshift(data)
    },

    REMOVE_TIMETABLE (state, item) {
      const index = state.timetables
        .map(item => item.id)
        .indexOf(item.id)
      if (index !== -1) {
        state.timetables.splice(index, 1)
      }
    },

    UPDATE_TIMETABLE (state, item) {
      const index = state.timetables
        .map(item => item.id)
        .indexOf(item.id)
      if (index !== -1) {
        for (const key in item) {
          state.timetables[index][key] = item[key]
        }
      }
    }
  },

  actions: {
    fetchTimetables ({ commit, rootState }, params = {}) {
      return api.timetables.list(rootState.auth.current_farm, { ...params })
        .then(({ data }) => {
          commit('SET_TIMETABLES', data.result.data)
          return data.result
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async createTimetable ({ commit, rootState }, payload) {
      const { data, error } = await api.timetables.create(rootState.auth.current_farm, payload)
        if (!error) {
          const item = {
            ...data.result,
            load_time: 0,
            count: 0,
            date: data.result.planned_at + ' 00:00:00'
          }
          if (!item.recipe_name) {
            item.recipe_name = item.recipe.name
          }
          commit('ADD_TIMETABLE', item)
        }

        return { data, error }
    },

    createTimetableSchedule ({ commit, rootState }, payload) {
      return api.timetables.createSchedule(rootState.auth.current_farm, payload)
        .then(({ data }) => {
          return { success: data.success }
        })
        .catch((error) => {
          return error.response.data
        })
    },

    updateTimetable ({ commit, rootState }, payload) {
      return api.timetables.update(rootState.auth.current_farm, payload)
        .then(({ data }) => {
          if (data.result) {
            commit('UPDATE_TIMETABLE', data.result)
          }
          return { success: data.success }
        })
        .catch((error) => {
          return error.response.data
        })
    },

    updateTimetableSchedule ({ commit, rootState }, payload) {
      return api.timetables.updateSchedule(rootState.auth.current_farm, payload)
        .then(({ data }) => {
          if (data.result) {
            commit('UPDATE_TIMETABLE', data.result)
          }
          return { success: data.success }
        })
        .catch((error) => {
          return error.response.data
        })
    },

    deleteTimetable ({ commit, rootState }, item) {
      item.id = item.id || item.timetable_id
      return api.timetables.delete(rootState.auth.current_farm, item.id)
        .then(({ data }) => {
          commit('REMOVE_TIMETABLE', item)
          return { success: data.success }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}

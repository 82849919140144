import _ from 'lodash'
import api from '@/api'

export default {
  state: {
    silages: [],
    silagesList: [],
    silagesFilters: {}
  },

  getters: {
    silages (state) {
      return state.silages
    },

    sortedSilages (state) {
      return state.silages
        .sort((a, b) => a.housing_name > b.housing_name ? 1 : -1)
    },

    silagesFilters (state) {
      return state.silagesFilters
    },

    hasSilagesFilters (state) {
      return !_.isEmpty(state.silagesFilters)
    },

    silagesList (state) {
      return state.silagesList
    }
  },

  mutations: {
    SET_SILAGES (state, silages) {
      state.silages = silages
    },

    ADD_SILAGE (state, silage) {
      state.silages.unshift(silage)
    },

    UPDATE_SILAGE (state, silage) {
      const index = state.silages
        .findIndex(item => item.id === silage.id)
      if (index !== -1) {
        const item = state.silages[index]
        for (const key in silage) {
          item[key] = silage[key]
        }
      }
    },

    SET_SILAGES_FILTERS (state, filters) {
      state.silagesFilters = filters
    },

    SET_SILAGES_LIST (state, silages) {
      state.silagesList = silages
    }
  },

  actions: {
    async fetchSilages ({ commit, state, rootState }, params = {}) {
      const { data } = await api.silages.list(rootState.auth.current_farm, { ...params, ...state.silagesFilters })
      commit('SET_SILAGES', data.result.data)
      return data.result
    },

    applySilagesFilters ({ commit }, filters = {}) {
      commit('SET_SILAGES_FILTERS', filters)
    },

    async fetchSilagesList ({ commit, rootState }) {
      const { data } = await api.lists.getSilages(rootState.auth.current_farm)
      commit('SET_SILAGES_LIST', data.result)
      return data.result
    },

    async addSilage ({ commit, rootState }, payload) {
      const { data, error } = await api.silages.create(rootState.auth.current_farm, payload)
      if (!error) {
        commit('ADD_SILAGE', data)
      }
      return { data, error }
    },

    async updateSilage ({ commit, rootState }, { id, payload }) {
      const { data, error } = await api.silages.updateById(rootState.auth.current_farm, id, payload)
      if (!error) {
        commit('UPDATE_SILAGE', data)
      }
      return { data, error }
    }
  }
}

<template lang="pug">
  .v-checkbox(
    :class="classes")
    label.v-checkbox__label
      input(
        type="checkbox"
        :disabled="disabled"
        :checked="value"
        @input="onChange"
      ).v-checkbox__input
      span.v-checkbox__box
      | {{ label }}

</template>

<script>
export default {
  name: 'VCheckbox',

  props: {
    value: Boolean,
    disabled: Boolean,
    label: {
      type: String,
      default: null
    }
  },

  computed: {
    classes () {
      return {
        'v-checkbox--disabled': this.disabled
      }
    }
  },

  methods: {
    onChange (event) {
      if (!this.disabled) {
        this.$emit('input', event.target.checked)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .v-checkbox {
    position: relative;
    display: flex;

    &--disabled {
      .v-checkbox__box {
        cursor: not-allowed;
        background-color: $color-gray-light-3;
        border: .2rem solid rgba($color-gray-light-2, .8);
      }
    }

    &__label {
      padding-left: 3rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
      cursor: pointer;
      user-select: none;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      /* Checked */
      &:checked + .v-checkbox__box {
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' fill='%231DA643' height='7' viewBox='0 0 407 316.03'%3E%3Cpath d='M155.85,309.94a20.77,20.77,0,0,1-29.39,0L9.13,192.59a31.16,31.16,0,0,1,0-44.07l14.69-14.7a31.16,31.16,0,0,1,44.08,0l73.26,73.26L339.1,9.13a31.17,31.17,0,0,1,44.08,0l14.69,14.7a31.16,31.16,0,0,1,0,44.07Z'/%3E%3C/svg%3E");
      }
    }

    &__box {
      position: absolute;
      margin-top: -.1rem;
      margin-left: -2.8rem;
      width: 1.8rem;
      height: 1.8rem;
      background-color: $color-white;
      border: .2rem solid rgba($color-black, .5);
      border-radius: .2rem;
    }
  }
</style>

export default {
  state: {
    isMobileMenuOpened: false
  },

  mutations: {
    OPEN_MOBILE_MENU (state) {
      state.isMobileMenuOpened = true
    },

    CLOSE_MOBILE_MENU (state) {
      state.isMobileMenuOpened = false
    }
  },

  actions: {
    openMobileMenu (context) {
      context.commit('OPEN_MOBILE_MENU')
    },

    closeMobileMenu (context) {
      context.commit('CLOSE_MOBILE_MENU')
    }
  },

  getters: {
    isMobileMenuOpened: (state) => state.isMobileMenuOpened
  }
}

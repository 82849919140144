<template lang="pug">
  .v-time-picker.noselect
    v-dropdown(
      width-auto
      position-right)
      slot(
        :time="time")
      template(slot="content")
        v-card.mb-2(
          elevated
          overflow-hidden)
          .v-time-picker__header
            .v-time-picker__col
              span HH
            .v-time-picker__col
              span mm
          .v-time-picker__content
            .v-time-picker__col
              vue-scroll
                v-list
                  v-list-item(
                    v-for="h in 24"
                    :key="h"
                    :active="hours === h - 1"
                    @click.native="hours = h - 1")
                    | {{ h < 11 ? `0${h - 1}` : h - 1 }}
            .v-time-picker__col
              vue-scroll
                v-list
                  v-list-item(
                    v-for="m in 60"
                    :key="m"
                    :active="minutes === m - 1"
                    @click.native="minutes = m - 1")
                    | {{ m  < 11 ? `0${m - 1}` : m - 1 }}

          //- .v-time-picker__actions
            v-btn(
              block
              ignore-group) {{ 'actions.select' | translate }}

</template>

<script>
export default {
  name: 'VTimePicker',

  props: {
    value: String
  },

  beforeMount () {
    const today = new Date()
    this.hours = today.getHours()
    this.minutes = today.getMinutes()
  },

  data: () => ({
    hours: 0,
    minutes: 0
  }),

  computed: {
    time () {
      const hh = this.hours < 10
        ? `0${this.hours}`
        : this.hours
      const mm = this.minutes < 10
        ? `0${this.minutes}`
        : this.minutes
      return `${hh}:${mm}`
    }
  },

  watch: {
    time (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
  .v-time-picker {

    &__header {
      display: flex;
      padding: 1.2rem 0;
      border-bottom: 1px solid rgba(0, 0, 0, .05);

      span {
        opacity: .4;
        font-size: 1.6rem;
        padding-left: 1.8rem;
      }
    }

    &__content {
      display: flex;
      max-height: 20rem;
    }

    // &__actions {
    //   padding: 1.5rem;
    //   border-top: 1px solid rgba(0, 0, 0, .05);
    // }

    &__col {
      width: 50%;

      &:not(:last-child) {
        position: relative;

        &::after {
          position: absolute;
          content: "";
          top: 0;
          bottom: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background-color: rgba(0, 0, 0, .05);
        }
      }
    }
  }
</style>

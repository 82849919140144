import { execute, addQueryParams } from '@/api/utils'
import { GET, POST, PUT, DELETE } from '@/api/types'

export default {
  list (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/mixers`, params))
  },

  create (farmID, payload) {
    return execute(POST, `farms/${farmID}/mixers`, payload)
  },

  getById (farmID, id) {
    return execute(GET, `farms/${farmID}/mixers/${id}`)
  },

  updateById (farmID, id, payload) {
    return execute(PUT, `farms/${farmID}/mixers/${id}`, payload)
  },

  deleteById (farmID, id) {
    return execute(DELETE, `farms/${farmID}/mixers/${id}`)
  }
}

import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import auth from './modules/auth/index'
import companies from './modules/companies/index'
import farm from './modules/farm/index'
import recipes from './modules/recipes/index'
import mixers from './modules/mixers/index'
import silages from './modules/silages/index'
import housings from './modules/housings/index'
import ingredients from './modules/ingredients/index'
import supplies from './modules/supplies/index'
import writeOffs from './modules/writeOffs/index'
import providers from './modules/providers/index'
import timetables from './modules/timetables/index'
import users from './modules/users/index'
import permission from './modules/permission/index'
import lists from './modules/lists'
import reports from './modules/reports'
import rations from './modules/rations'
import doneProducts from './modules/done-products'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    auth,
    companies,
    farm,
    recipes,
    mixers,
    silages,
    housings,
    ingredients,
    supplies,
    writeOffs,
    providers,
    timetables,
    users,
    permission,
    lists,
    reports,
    rations,
    doneProducts
  },
  plugins: [vuexLocal.plugin]
})

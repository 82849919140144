import VCard from './VCard'
import VCardActions from './VCardActions'
import VCardTitle from './VCardTitle'
import VCardContent from './VCardContent'

export default {
  VCard,
  VCardActions,
  VCardTitle,
  VCardContent
}

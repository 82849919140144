<template lang="pug">
  .v-switch__wrapper
    label(v-if="label") {{ label }}
    input(
      :class="classes"
      :checked="value"
      :disabled="disabled"
      @input="onChange"
      type="checkbox"
    ).v-switch
</template>

<script>
export default {
  name: 'VSwitch',

  props: {
    label: String,
    value: Boolean,
    disabled: Boolean
  },

  methods: {
    onChange (event) {
      this.$emit('input', event.target.checked)
    }
  },

  computed: {
    classes () {
      return {
        'v-switch--disabled': this.disabled
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .v-switch__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.8rem;
    }
  }

  .v-switch {
    position: relative;
    width: 4rem;
    height: 2.4rem;
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: $color-gray-light-1;
    border-radius: 2rem;
    outline: none;
    transition: .4s ease;

    &--disabled {
      cursor: not-allowed;

      &::before {
        background-color: $color-gray-light-3 !important;
      }
    }

    &:checked {
      background-color: $color-green-light-1;

      &::before {
        left: 1.7rem;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: .2rem;
      width: 2.1rem;
      height: 2.1rem;
      box-shadow: 0 0.5px 2.5px rgba(0, 0, 0, 0.2),
        0px 0px 2px rgba(0, 0, 0, 0.1),
        0px 0px 10px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border-radius: 50%;
      transition: .4s ease;
    }
  }
</style>

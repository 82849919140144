import { execute, addQueryParams } from '@/api/utils'
import { GET, PUT, POST } from '@/api/types'

export default {
  list (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/silages`, params))
  },
  create (farmID, payload) {
    return execute(POST, `farms/${farmID}/silages`, payload)
  },
  updateById (farmID, id, payload) {
    return execute(PUT, `farms/${farmID}/silages/${id}`, payload)
  },
  getById (farmID, id) {
    return execute(GET, `farms/${farmID}/silages/${id}`)
  }
}

import api from '@/api'

export default {
  state: {
    permissionSections: [],
    permissionSection: [],
    permissionSectionId: null,
    permission: []
  },

  mutations: {
    SET_PERMISSION_SECTIONS (state, sections) {
      state.permissionSections = sections
    },

    SET_PERMISSION_SECTION (state, section) {
      state.permissionSection = section
    },

    SET_PERMISSION_SECTION_ID (state, id) {
      state.permissionSectionId = id
    },

    ADD_PERMISSION (state, permission) {
      state.permission.push(permission)
    }
  },

  actions: {
    fetchPermissionSections ({ commit }) {
      return api.permissions.list()
        .then(({ data }) => {
          commit('SET_PERMISSION_SECTIONS', data.result)
          return data.result
        })
        .catch((error) => {
          console.log(error)
        })
    },

    fetchPermissionSectionById ({ commit }, { id }) {
      return api.permissions.getById(id)
        .then(({ data }) => {
          commit('SET_PERMISSION_SECTION_ID', id)
          commit('SET_PERMISSION_SECTION', data.result.data)
          return data.result.data
        })
        .catch((error) => {
          console.log(error)
        })
    },

    addPermission ({ commit }, payload) {
      return api.permissions.create(payload)
        .then(({ data }) => {
          commit('ADD_PERMISSION', data.result)
          return data.result
        })
        .catch((error) => {
          console.error(error)
        })
    },

    updatePermissions ({ commit }, payload) {
      return api.permissions.update(payload)
        .then(({ data }) => {
        })
    }
  },

  getters: {
    permissionSection (state) {
      return state.permissionSection
    },

    permissionSectionId (state) {
      return state.permissionSectionId
    },

    permissionSections (state) {
      return state.permissionSections
    },

    permission (state) {
      return state.permission
    }
  }
}

<template lang="pug">
  v-date-picker(
    :locale="$i18n.locale()"
    mode="single"
    v-model="date"
    color="green"
    :attributes='attrs'
    :popover="{ placement: 'bottom', visibility: 'click' }"
    :available-dates="{start: this.dateFrom, end: this.dateTo}")
    v-text-field.mb-3(
      slot-scope="{ inputProps, inputEvents, isDragging }"
      v-bind="inputProps"
      v-on="inputEvents"
      :label="label | translate")
      template(slot="icon-right")
        font-awesome-icon(
          size="sm"
          :icon="['far', 'calendar-alt']")

</template>

<script>
import { formateDate } from '@/util/utils'

export default {
  name: 'VDateField',

  props: {
    value: String,
    label: {
      type: String,
      default: 'base.date'
    },
    dateFrom: Date,
    dateTo: Date
  },

  data: () => ({
    attrs: [
      {
        key: 'today',
        highlight: {
          color: 'green',
          fillMode: 'light'
        },
        dates: new Date()
      }
    ],
    date: null
  }),

  methods: {
    onInput (e) {
      this.$emit('input', e)
    }
  },

  watch: {
    date (value) {
      this.$emit('input', formateDate(value))
    },

    value (value) {
      if (this.value) {
        this.date = new Date(this.value)
      } else if (this.value === null) {
        this.date = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

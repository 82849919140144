<template lang="pug">
  hr(:class="classes").v-divider
</template>

<script>
export default {
  name: 'VDivider',

  props: {
    secondary: Boolean
  },

  computed: {
    classes () {
      return {
        'mv-3': !this.secondary,
        'v-divider--secondary': this.secondary
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';

  .v-divider {
    border: none;
    border-bottom: .1rem solid $color-brand;

    &--secondary {
      border: none;
      border-bottom: .3rem solid $color-divider;
    }
  }
</style>

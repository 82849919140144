import _ from 'lodash'
import api from '@/api'

export default {
  state: {
    writeOffs: [],
    writeOffsHistory: [],
    writeOffsFilters: {}
  },
  mutations: {
    SET_WRITE_OFFS (state, writeOffs) {
      state.writeOffs = writeOffs
    },

    ADD_WRITE_OFF (state, writeOff) {
      state.writeOffs.unshift(writeOff)
    },

    SET_WRITE_OFFS_FILTERS (state, filters) {
      state.writeOffsFilters = filters
    },

    SET_WRITE_OFFS_HISTORY (state, { ingredientID, result }) {
      state.writeOffsHistory[ingredientID] = result
      state.writeOffs.find(item => item.ingredient_id === ingredientID).histories = result
    },

    ADD_HISTORY (state, writeOff) {
      const index = state.writeOffs.findIndex(item => item.ingredient_id === writeOff.ingredient_id)
      if (index !== -1) {
        const item = state.writeOffs[index]
        for (const key in writeOff) {
          item[key] = writeOff[key]
        }
        state.writeOffs.splice(index, 1)
        state.writeOffs.unshift(writeOff)
      }
    }
  },
  actions: {
    fetchWriteOffs ({ commit, state, rootState }, params = {}) {
      return api.storage.getWriteOffs(rootState.auth.current_farm, { ...params, ...state.writeOffsFilters })
        .then(({ data }) => {
          commit('SET_WRITE_OFFS', data.result.data)
          return data.result
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async fetchWriteOffsHistory ({ commit, state, rootState }, payload) {
      const { ingredientID, params } = payload
      return api.storage.getWriteOffHistory(rootState.auth.current_farm, ingredientID, { ...state.suppliesFilters, ...params })
      .then(({ data }) => {
        const result = data.result.data
        commit('SET_WRITE_OFFS_HISTORY', { ingredientID, result })
        return data.result
      })
      .catch((error) => {
        console.error(error)
      })
    },
    applyWriteOffsFilters ({ commit }, filters = {}) {
      commit('SET_WRITE_OFFS_FILTERS', filters)
    },

    async addWriteOff ({ commit, rootState }, payload) {
      return await api.storage.createWriteOff(rootState.auth.current_farm, payload)
    }
  },
  getters: {
    writeOffs (state) {
      return state.writeOffs
    },

    writeOffsHistory (state) {
      return state.writeOffsHistory
    },

    writeOffsFilters (state) {
      return state.writeOffsFilters
    },

    hasWriteOffsFilters (state) {
      return !_.isEmpty(state.writeOffsFilters)
    }
  }
}

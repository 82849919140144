<template lang="pug" functional>
  .v-image-skeleton(
    :style="{ height: props.height ? `${props.height}px` : 'auto' }")

</template>

<script>

export default {
  name: 'VImageSkeleton',

  props: {
    height: {
      type: [String, Number],
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';
  @import '@/sass/abstracts/_variables.scss';

  .v-image-skeleton {
    width: 100%;
    border-radius: .8rem;

    @include skeleton;
  }
</style>

import api from '@/api'

export default {
  state: {
    companies: []
  },
  getters: {
    companies (state) {
      return state.companies
    }
  },
  mutations: {
    SET_COMPANIES (state, data) {
      state.companies = data
    }
  },
  actions: {
    fetchCustomerCompanies ({ commit }, params = {}) {
      api.companies.list(params).then(response => {
          commit('SET_COMPANIES', response.data)
        }
      )
    }
  }
}

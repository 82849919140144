<template lang="pug">
  transition(name="fadex")
    .v-dialog(v-if="value")
      vue-scroll
        .v-dialog__inner(@click.self="closeDialog")
          .v-dialog__content(
            :style="{ 'max-width': `${maxWidth}px` }")
            button.v-dialog-close(@click="closeDialog")
              i.i-close
            slot

</template>

<script>
export default {
  name: 'VDialog',

  props: {
    value: Boolean,
    maxWidth: [Number, String]
  },

  methods: {
    closeDialog () {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .v-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(51, 51, 51, .2);

    &__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8rem 2rem;
      min-height: 100vh;
    }

    &__content {
      position: relative;
      width: 100%;
    }
  }

  .v-dialog-close {
    display: inline-flex;
    position: absolute;
    z-index: 5;
    top: 1.4rem;
    right: 1.4rem;
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    background-color: $modal-icon-bg;
    // transition: .3s linear;

    &:hover {
      background-color: darken($modal-icon-bg, 7%);
    }

    &:active {
      background-color: darken($modal-icon-bg, 10%);
    }

    &:focus {
      outline: none;
    }

    .i-close {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
</style>

import { execute, addQueryParams } from '@/api/utils'
import { DELETE, GET, POST, PUT } from '@/api/types'

export default {
  list (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/users`, params))
  },
  getById (farmID, userID) {
    return execute(GET, `farms/${farmID}/users/${userID}`)
  },
  create (farmID, payload) {
    return execute(POST, `farms/${farmID}/users`, payload)
  },
  update (farmID, userID, payload) {
    return execute(PUT, `farms/${farmID}/users/${userID}`, payload)
  },
  delete (farmID, userID) {
    return execute(DELETE, `farms/${farmID}/users/${userID}`)
  }
}

<template lang="pug" functional>
  ul.v-list
    slot
</template>

<script>
export default {
  name: 'VList'
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';

  .v-list {
    &__item {
      cursor: pointer;
      padding: 1.4rem 1.8rem;
      font-size: 1.6rem;

      &--active {
        color: $color-green;
        background-color: rgba($color-green, .2);

        &:hover {
          background-color: rgba($color-green, .3);
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $color-gray-light-3;
      }

      &:not(.v-list__item--active):hover {
        background-color: $color-gray-light-4;
      }
    }
  }
</style>

<template lang="pug">
  .v-editable(@click="toggleEdit")
    input.v-editable__input(
      v-if="toggled"
      v-focus
      v-model="text"
      v-autowidth="autowidthOptions"
      v-click-outside="toggleEdit"
      :style="inputStyles"
      :type="type"
      @click.stop
      @keyup.enter="toggleEdit")
    .v-editable__inner(v-else)
      slot
    span.v-editable__icon(
      v-if="!disabled")
      font-awesome-icon(
        size="xs"
        :icon="toggled ? 'check' : 'pencil-alt'")

</template>

<script>
export default {
  name: 'VEditable',

  props: {
    type: {
      type: String,
      default: 'Number'
    },
    initState: Boolean,
    disabled: Boolean
  },

  mounted () {
    this.initInputStyles()
    if (this.initState) {
      this.toggleEdit()
    }
  },

  data: () => ({
    text: '',
    toggled: false,
    inputStyles: {
      height: '16px',
      fontSize: '16px',
      fontWeight: '500'
    },
    autowidthOptions: {
      maxWidth: '350px',
      minWidth: '5px',
      comfortZone: 0
    }
  }),

  methods: {
    toggleEdit () {
      if (!this.disabled) {
        if (!this.toggled) {
          this.toggled = true
        } else if (this.text.length) {
          this.toggled = false
        }
        if (this.$slots.default.length) {
          const el = this.$slots.default[0].children[0]
          if (this.toggled) {
            this.text = el.text !== '0' ? el.text : ''
          } else {
            if (this.text.length) {
              if (this.text !== el.text) {
                this.onChange()
              }
              this.onClose()
            }
          }
        }
      }
    },

    initInputStyles () {
      if (this.$slots.default.length) {
        const el = this.$slots.default[0].elm
        const computedStyle = window.getComputedStyle(el, null)
        this.inputStyles.fontSize = computedStyle.getPropertyValue('font-size')
        this.inputStyles.fontWeight = computedStyle.getPropertyValue('font-weight')
        this.inputStyles.height = computedStyle.getPropertyValue('height')
      }
    },

    onChange () {
      this.$emit('change', this.text)
    },

    onClose () {
      this.$emit('close', this.text)
    }
  }
}
</script>

<style lang="scss">
  .v-editable {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &__inner > * {
      margin: 0!important;
      border-bottom: 1px solid #ccc;
    }

    &__input {
      line-height: 1;
      border: none;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.18);

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }

    &__icon {
      opacity: 0;
      transition: opacity .3s;
      cursor: pointer;
      margin-left: 10px;
    }

    &:hover {
      .v-editable__icon {
        opacity: 1;
      }
    }
  }
</style>

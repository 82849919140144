<template lang="pug" functional>
  .v-loader-button
    .v-loader-button__icon(v-for="item in 3")

</template>

<script>
  export default {
    name: 'VLoaderButton'
  }
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .v-loader-button {
    display: inline-block;
    position: relative;
    width: 4.6rem;
    height: 4.6rem;

    &__icon {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 2.5rem;
      height: 2.5rem;
      margin: 1.2rem;
      border: .4rem solid $color-white;
      border-radius: 50%;
      animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $color-white transparent transparent transparent;
    }

    &__icon:nth-child(3) {
      animation-delay: -0.15s;
    }

    &__icon:nth-child(2) {
      animation-delay: -0.3s;
    }

    &__icon:nth-child(1) {
      animation-delay: -0.45s;
    }
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>

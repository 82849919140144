import { execute, addQueryParams } from '@/api/utils'
import { GET, POST, PUT } from '@/api/types'

const ENDPOINT = 'permission/sections'

export default {
  list (params = {}) {
    return execute(GET, addQueryParams(`${ENDPOINT}`, params))
  },
  getById (id) {
    return execute(GET, addQueryParams(`${ENDPOINT}/${id}`))
  },
  create (payload) {
    return execute(POST, ENDPOINT, payload)
  },
  update (payload) {
    return execute(PUT, ENDPOINT, payload)
  }
}

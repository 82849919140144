import Vue from 'vue'
import VueRouter from 'vue-router'
import ll from './lazyLoading'
import auth from '@/store/modules/auth'
import { getRouteRedirect } from '@/util/utils'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: auth.state.current_farm ? '/select-farm' : '/farm',
    component: ll('app/Index'),
    children: [
      {
        path: 'select-farm',
        component: ll('app/farm/farms/Main'),
        meta: { requiresAuth: true }
      },
      {
        path: '/change-password',
        component: ll('ChangePassword'),
        meta: { requiresAuth: true }
      },
      {
        path: 'farm',
        component: ll('app/farm/Index'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'planning',
            component: ll('app/farm/planning/Index')
          },
          {
            path: 'rations',
            component: ll('app/farm/rations/Index')
          },
          {
            path: 'storage',
            component: ll('app/farm/storage/Index'),
            children: [
              {
                path: 'ingredients',
                component: ll('app/farm/storage/ingredients/Index')
              },
              {
                path: 'final-product',
                component: ll('app/farm/storage/final-product/Index')
              },
              {
                path: 'supplies',
                component: ll('app/farm/storage/supplies/Index')
              },
              {
                path: 'expense',
                component: ll('app/farm/storage/expense/Index')
              },
              {
                path: 'suppliers',
                component: ll('app/farm/storage/suppliers/Index')
              }
            ]
          },
          {
            path: 'reports',
            component: ll('app/farm/reports/Index')
          },
          {
            path: 'settings',
            component: ll('app/farm/settings/Index'),
            children: [
              {
                path: 'silage',
                component: ll('app/farm/settings/silage/Index')
              },
              {
                path: 'employees',
                component: ll('app/farm/settings/employees/Index')
              },
              {
                path: 'mixer',
                component: ll('app/farm/settings/mixer/Index')
              },
              {
                path: 'buildings',
                component: ll('app/farm/settings/buildings/Index')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    component: ll('Login')
  },
  {
    path: '/reset-password/:user',
    component: ll('ResetPassword')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')
  const user = JSON.parse(loggedIn)
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/login')
  }
  if (user) {
    switch (to.path) {
      case '/farm':
      case '/farm/storage':
      case '/farm/settings':
        next(getRouteRedirect(user.user.role, to.path))
        break
    }
  }
  next()
})

export default router

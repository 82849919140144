import api from '@/api'

export default {
  state: {
    users: []
  },
  mutations: {
    SET_USERS (state, users) {
      state.users = users
    },

    ADD_USER (state, user) {
      state.users.unshift(user)
    },

    UPDATE_USER (state, user) {
      const index = state.users
        .map(item => item.id)
        .indexOf(user.id)
      if (index !== -1) {
        const item = state.users[index]
        for (const key in user) {
          item[key] = user[key]
        }
      }
    },

    REMOVE_USER (state, id) {
      const index = state.users
        .map(item => item.id)
        .indexOf(id)
      if (index !== -1) {
        state.users.splice(index, 1)
      }
    }
  },
  actions: {
    fetchUsers ({ commit, rootState }, params) {
      return api.users.list(rootState.auth.current_farm, params)
        .then(({ data }) => {
          commit('SET_USERS', data.result.data)
          return data.result
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async addUser ({ commit, rootState }, payload) {
      const { data, error } = await api.users.create(rootState.auth.current_farm, payload)
          if (!error) {
            commit('ADD_USER', data.result)
          }
          return { data, error }
    },

    async updateUser ({ commit, rootState }, { id, payload }) {
      const { data, error } = await api.users.update(rootState.auth.current_farm, id, payload)
        if (!error) {
          commit('UPDATE_USER', data.result)
        }
        return { data, error }
    },

    deleteUser ({ commit, rootState }, { id }) {
      return api.users.delete(rootState.auth.current_farm, id)
        .then(({ data }) => {
          commit('REMOVE_USER', id)
          return data.result
        })
        .catch((error) => {
          console.error(error)
        })
    }
  },
  getters: {
    users (state) {
      return state.users
    }
  }
}

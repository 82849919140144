import api from '@/api'

export default {
  state: {
    rations: [],
    rationsList: [],
    rationRecipes: []
  },

  getters: {
    rations (state) {
      return state.rations
    },

    rationsList (state) {
      return state.rationsList
    },

    rationRecipes (state) {
      return state.rationRecipes
    }
  },

  mutations: {
    SET_RATIONS (state, rations) {
      state.rations = rations
    },

    SET_RATIONS_LIST (state, rations) {
      state.rationsList = rations
    },

    ADD_RATION (state, rations) {
      state.rations.unshift(rations)
    },

    UPDATE_RATION (state, ration) {
      const index = state.rations
        .map(item => item.id)
        .indexOf(ration.id)
      if (index !== -1) {
        const item = state.rations[index]
        for (const key in ration) {
          item[key] = ration[key]
        }
      }
    },

    DELETE_RATION (state, ration) {
      const index = state.rations.findIndex(item => item.id === ration.id)
      if (index !== -1) {
        state.rations.splice(index, 1)
      }
    },

    SET_RATION_RECIPES (state, { rationID, isActive, result }) {
      state.rationRecipes[rationID] = result
      if (isActive) {
        state.rations.find(item => item.id === rationID).active_recipes = result
      } else {
        state.rations.find(item => item.id === rationID).archive_recipes = result
      }
    }
  },

  actions: {
    async fetchRations ({ commit, rootState }, params = {}) {
      const { data } = await api.rations.list(rootState.auth.current_farm, params)
      commit('SET_RATIONS', data.result.data)
      return data.result
    },

    async fetchRationsList ({ commit, rootState }) {
      const { data } = await api.lists.getRations(rootState.auth.current_farm)
      commit('SET_RATIONS_LIST', data.result)
      return data.result
    },

    async createRation ({ commit, rootState }, payload) {
      const { data, error } = await api.rations.create(rootState.auth.current_farm, payload)
      if (!error) {
        commit('ADD_RATION', data.result)
        return data.result
      }
      return { data: data.data, error }
    },

    async updateRation ({ commit, rootState }, { id, payload }) {
      const { data, error } = await api.rations.updateById(rootState.auth.current_farm, id, payload)
      if (!error) {
        commit('UPDATE_RATION', data.result)
        return data.result
      }
      return { data: data.data, error }
    },

    async deleteRation ({ commit, rootState }, { item }) {
      const data = await api.rations.deleteById(rootState.auth.current_farm, item.id)
      commit('DELETE_RATION', item)
      return data
    },

    async fetchRationRecipes ({ commit, rootState }, payload) {
      const { rationID, params } = payload
      const { data, error } = await api.rations.getRecipes(rootState.auth.current_farm, rationID, params)
      if (!error) {
        const result = data.result.data
        const isActive = params.is_active
        commit('SET_RATION_RECIPES', { rationID, isActive, result })
        return data.result
      }
      return { data: data.data, error }
    }
  }
}

import { execute, addQueryParams } from '@/api/utils'
import { GET } from '@/api/types'

export default {
  list (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/reports`, params))
  },

  export (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/export`, params), null, null, null, 'blob')
  },

  exportShort (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/export/short`, params), null, null, null, 'blob')
  },

  exportIngredients (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/reports/ingredients`, params), null, null, null, 'blob')
  },

  exportProducts (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/reports/products`, params), null, null, null, 'blob')
  }
}

import { execute, addQueryParams } from '@/api/utils'
import { GET, POST, PUT, DELETE } from '@/api/types'

export default {
  list (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/timetables`, params))
  },
  updatePremix (farmID, id, payload = {}) {
    return execute(PUT, `farms/${farmID}/timetables/premix/${id}`, payload)
  },
  stopBatch (farmID, timetable, batch) {
    return execute(PUT, `farms/${farmID}/timetables/${timetable}/batches/${batch}/stop`)
  },
  create (farmID, payload) {
    return execute(POST, `farms/${farmID}/timetables`, payload)
  },
  createSchedule (farmID, payload) {
    return execute(POST, `farms/${farmID}/timetables/schedule`, payload)
  },
  update (farmID, payload) {
    return execute(PUT, `farms/${farmID}/timetables/${payload.id}`, payload.data)
  },
  updateSchedule (farmID, payload) {
    return execute(PUT, `farms/${farmID}/timetables/schedule/${payload.id}`, payload.data)
  },
  delete (farmID, timetableID) {
    return execute(DELETE, `farms/${farmID}/timetables/${timetableID}`)
  }
}

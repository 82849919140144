import Vue from 'vue'
import vuexI18n from 'vuex-i18n'
import store from '@/store'
import { LANG_VAR_LOCAL_STORAGE_KEY } from '@/util/consts'

import uk from './uk.json'
import ru from './ru.json'
import en from './en.json'
import pl from './pl.json'

const UK = 'uk'
const RU = 'ru'
const EN = 'en'
const PL = 'pl'

Vue.use(vuexI18n.plugin, store)

Vue.i18n.add(UK, uk)
Vue.i18n.add(RU, ru)
Vue.i18n.add(EN, en)
Vue.i18n.add(PL, pl)

Vue.i18n.fallback(UK)
Vue.i18n.set(localStorage.getItem(LANG_VAR_LOCAL_STORAGE_KEY) || UK)

<template lang="pug" functional>
  .v-loader
    .v-loader__icon(v-for="item in 4")
</template>

<script>
  export default {
    name: 'VLoader'
  }
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .v-loader {
    display: inline-block;
    position: absolute;
    width: 8rem;
    height: 2rem;
    top: 30%;
    left: 60%;
  }
  .v-loader .v-loader__icon {
    position: absolute;
    top: 0;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background: $color-brand;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .v-loader .v-loader__icon:nth-child(1) {
    left: .8rem;
    animation: circle-1 0.6s infinite;
  }
  .v-loader .v-loader__icon:nth-child(2) {
    left: .8rem;
    animation: circle-2 0.6s infinite;
  }
  .v-loader .v-loader__icon:nth-child(3) {
    left: 3.2rem;
    animation: circle-2 0.6s infinite;
  }
  .v-loader .v-loader__icon:nth-child(4) {
    left: 5.6rem;
    animation: circle-3 0.6s infinite;
  }
  @keyframes circle-1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes circle-3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes circle-2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(2.4rem, 0);
    }
  }
</style>

<template lang="pug">
  paginate(
    :value="value"
    :page-count="pageCount"
    :click-handler="clickHandler"
    :prev-text="$t('actions.toggle_prev')"
    prev-class="pagination__item--first"
    :next-text="$t('actions.toggle_next')"
    next-class="pagination__item--last"
    container-class="pagination"
    page-class="pagination__item"
    active-class="pagination__item--active"
    disabled-class="pagination__item--disabled"
    page-link-class="pagination__link"
    @input="onInput")

</template>

<script>
export default {
  name: 'VPaginate',

  props: {
    value: Number,
    pageCount: Number,
    clickHandler: Function
  },

  methods: {
    onInput (e) {
      this.$emit('input', e)
    }
  }
}
</script>

<style lang="sass" scoped>
</style>

import _ from 'lodash'
import api from '@/api'

export default {
  state: {
    reports: [],
    reportsFilters: {}
  },

  getters: {
    reports (state) {
      return state.reports
    },

    reportsFilters (state) {
      return state.reportsFilters
    },

    hasReportsFilters (state) {
      for (const [, value] of Object.entries(state.reportsFilters)) {
        if (!_.isEmpty(value)) {
          return true
        }
      }
      return false
    }
  },

  mutations: {
    SET_REPORTS (state, reports) {
      state.reports = reports
    },

    SET_REPORTS_FILTERS (state, filters) {
      state.reportsFilters = filters
    }
  },

  actions: {
    async fetchReports ({ commit, state, rootState }, params = {}) {
      const queryParams = { ...params, ...state.reportsFilters }
      const { data, error } = await api.reports.list(rootState.auth.current_farm, queryParams)
      if (!error) {
        commit('SET_REPORTS', data.result.data)
        return data.result
      }
      return { data: data.result.data, error }
    },

    async fetchReportsExport ({ commit, state, rootState }, params = {}) {
      const queryParams = { ...params, ...state.reportsFilters }
      const { data } = await api.reports.export(rootState.auth.current_farm, queryParams)
      return data
    },

    async fetchReportsShort ({ commit, state, rootState }, params = {}) {
      const queryParams = { ...params, ...state.reportsFilters }
      const { data } = await api.reports.exportShort(rootState.auth.current_farm, queryParams)
      return data
    },

    async fetchReportsIngredients ({ commit, state, rootState }, params = {}) {
      const queryParams = { ...params, ...state.reportsFilters }
      const { data } = await api.reports.exportIngredients(rootState.auth.current_farm, queryParams)
      return data
    },

    async fetchReportsProducts ({ commit, state, rootState }, params = {}) {
      const queryParams = { ...params, ...state.reportsFilters }
      const { data } = await api.reports.exportProducts(rootState.auth.current_farm, queryParams)
      return data
    },

    applyReportsFilters ({ commit }, filters = {}) {
      commit('SET_REPORTS_FILTERS', filters)
    }
  }
}

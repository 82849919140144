import { execute, addQueryParams } from '@/api/utils'
import { GET, PUT, POST } from '@/api/types'

export default {
  getSupplies (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/supplies/ingredients`, params))
  },
  getSuppliesHistory (farmID, ingredientID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/supplies/ingredients/${ingredientID}`, params))
  },
  createSupply (farmID, payload) {
    return execute(POST, `farms/${farmID}/supplies`, payload)
  },
  updateSupply (farmID, supplyID, payload) {
    return execute(PUT, `farms/${farmID}/supplies/${supplyID}`, payload)
  },
  getWriteOffs (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/write-off/ingredients`, params))
  },
  getWriteOffHistory (farmID, ingredientID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/write-off/ingredients/${ingredientID}`, params))
  },
  createWriteOff (farmID, payload) {
    return execute(POST, `farms/${farmID}/write-off`, payload)
  }
}

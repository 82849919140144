<template lang="pug">
  .v-card(:class="classes")
    slot
</template>

<script>
export default {
  name: 'VCard',

  props: {
    white: Boolean,
    danger: Boolean,
    elevated: Boolean,
    secondary: Boolean,
    overflowHidden: Boolean,
    disabled: Boolean
  },

  computed: {
    classes () {
      return {
        'v-card--white': this.white,
        'v-card--secondary': this.secondary,
        'v-card--danger': this.danger,
        'v-card--disabled': this.disabled,
        'v-card--elevated': this.elevated,
        'v-card--overflow-hiden': this.overflowHidden
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';
  @import '_variables.scss';

  .v-card {
    width: 100%;
    border-radius: $v-card-border-radius;
    background-color: $color-white;

    &--secondary {

      &:not(.v-card--white) {
        border: .1rem solid $color-gray-light-2;
        background-color: $color-gray-light-3;
      }

      .v-card__title {
        color: $color-gray;
        font-size: 1.5rem;
        padding: 1.5rem 2rem;

        &:not(.v-card--white) {
          border-bottom: .1rem solid $color-gray-light-2;
        }
      }

      .v-card__content {
        padding: 2rem 2rem 2rem;

        &:not(.v-card--white) {
          background-color: $color-gray-light-4;
        }
      }

      .v-card__actions {
        padding: 1.5rem 2rem;

        &:not(.v-card--white) {
          border-top: .1rem solid $color-gray-light-2;
        }
      }
    }

    &--disabled {
      background-color: $color-gray-light-5;
    }

    &--elevated {
      box-shadow: $v-card-shadow-1;
    }

    &--overflow-hiden {
      overflow: hidden;
    }

    &--danger {
      border: .1rem solid $color-red;
      background-color: $color-red-lighten-4;
    }

    &__title {
      padding: 2.5rem 2.5rem 0;
      font-size: 1.8rem;
    }

    &__content {
      padding: 2rem 2.5rem 2.5rem;
      font-size: 1.4rem;
      font-weight: 400;
    }

    &__actions {
      padding: 0 2.5rem 2.5rem;

      &--center {
        display: flex;
        justify-content: center;
      }
    }
  }
</style>

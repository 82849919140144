import _ from 'lodash'
import api from '@/api'

export default {
  state: {
    supplies: [],
    suppliesHistory: [],
    suppliesFilters: {}
  },
  mutations: {
    SET_SUPPLIES (state, supplies) {
      state.supplies = supplies
    },

    ADD_SUPPLY (state, supply) {
      state.supplies.unshift(supply)
    },

    SET_SUPPLIES_FILTERS (state, filters) {
      state.suppliesFilters = filters
    },

    SET_SUPPLIES_HISTORY (state, { ingredientID, result }) {
      state.suppliesHistory[ingredientID] = result
      state.supplies.find(item => item.ingredient_id === ingredientID).histories = result
    },

    ADD_HISTORY (state, supply) {
      const index = state.supplies.findIndex(item => item.ingredient_id === supply.ingredient_id)
      if (index !== -1) {
        const item = state.supplies[index]
        for (const key in supply) {
          item[key] = supply[key]
        }
        state.supplies.splice(index, 1)
        state.supplies.unshift(supply)
      }
    },
    UPDATE_SUPPLY (state, supply) {
      const supplies = state.supplies.filter(i => i.id !== supply.id)
      if (supplies.length < state.supplies.length) {
        supplies.unshift(supply)
        state.supplies = supplies
      } else {
        const histories = state.suppliesHistory[supply.ingredient_id].filter(i => i.id !== supply.id)
        histories.unshift(supply)
        state.suppliesHistory[supply.ingredient_id] = histories
      }
    }
  },
  actions: {
    fetchSupplies ({ commit, state, rootState }, params = {}) {
      return api.storage.getSupplies(rootState.auth.current_farm, { ...params, ...state.suppliesFilters })
        .then(({ data }) => {
          commit('SET_SUPPLIES', data.result.data)
          return data.result
        })
        .catch((error) => {
          console.error(error)
        })
    },
    fetchSuppliesHistory ({ commit, state, rootState }, payload) {
      const { ingredientID, params } = payload
      return api.storage.getSuppliesHistory(rootState.auth.current_farm, ingredientID, { ...state.suppliesFilters, ...params })
      .then(({ data }) => {
        if (ingredientID > 0) {
          const result = data.result.data
          commit('SET_SUPPLIES_HISTORY', { ingredientID, result })
          return data.result
        }
      })
      .catch((error) => {
        console.error(error)
      })
    },

    applySuppliesFilters ({ commit }, filters = {}) {
      commit('SET_SUPPLIES_FILTERS', filters)
    },

    async addSupply ({ commit, rootState }, payload) {
      const { data, error } = await api.storage.createSupply(rootState.auth.current_farm, payload)
      if (!error) {
          commit('ADD_HISTORY', data.result)
      }
      return { data, error }
    },

    async updateSupply ({ commit, rootState }, form) {
      const { supplyID, payload } = form
      const { data, error } = await api.storage.updateSupply(rootState.auth.current_farm, supplyID, payload)
        if (!error) {
          commit('UPDATE_SUPPLY', data.result)
        }
        return { data, error }
    }
  },
  getters: {
    supplies (state) {
      return state.supplies
    },

    suppliesHistory (state) {
      return state.suppliesHistory
    },

    suppliesFilters (state) {
      return state.suppliesFilters
    },

    hasSuppliesFilters (state) {
      return !_.isEmpty(state.suppliesFilters)
    }
  }
}

import _ from 'lodash'
import api from '@/api'

export default {
  state: {
    recipes: [],
    recipesList: [],
    recipesFilters: {}
  },

  getters: {
    recipes (state) {
      return state.recipes
    },

    recipesList (state) {
      return state.recipesList
    },

    recipesFilters (state) {
      return state.recipesFilters
    },

    hasRecipesFilters (state) {
      return !_.isEmpty(state.recipesFilters)
    }
  },

  mutations: {
    SET_RECIPES (state, recipes) {
      state.recipes = recipes
    },

    SET_RECIPES_LIST (state, recipesList) {
      state.recipesList = recipesList
    },

    ADD_RECIPE (state, recipe) {
      state.recipes.unshift(recipe)
    },

    SET_RECIPES_FILTERS (state, filters) {
      state.recipesFilters = filters
    },

    UPDATE_RECIPE (state, recipe) {
      const index = state.recipes
        .map(item => item.id)
        .indexOf(recipe.id)
      if (index !== -1) {
        for (const key in recipe) {
          state.recipes[index][key] = recipe[key]
        }
      }
    },

    REMOVE_RECIPE (state, recipe) {
      const index = state.recipes
        .map(item => item.id)
        .indexOf(recipe.id)
      if (index !== -1) {
        state.recipes.splice(index, 1)
      }
    }
  },

  actions: {
    applyRecipesFilters ({ commit, dispatch }, filters = {}) {
      commit('SET_RECIPES_FILTERS', filters)
      return dispatch('fetchRecipes')
    },

    async fetchRecipes ({ commit, rootState }, params = {}) {
      const { data } = await api.recipes.list(rootState.auth.current_farm, params)
      commit('SET_RECIPES', data.result.data)
      return data.result
    },

    async fetchRecipesList ({ commit, rootState }, params = {}) {
      const { data } = await api.lists.getRecipes(rootState.auth.current_farm, params)
      commit('SET_RECIPES_LIST', data.result)
      return data.result
    },

    async createRecipe ({ commit, rootState }, item) {
      const { data } = await api.recipes.create(rootState.auth.current_farm, item)
      if (data.success) {
        commit('ADD_RECIPE', data.result)
        return { data: data.result, error: false }
      }
      return { data: data.data, error: !data.success }
    },

    async updateRecipe ({ commit, rootState }, item) {
      const { data } = await api.recipes.updateById(rootState.auth.current_farm, item.id, item)
      commit('UPDATE_RECIPE', data.result)
      return data.result
    },

    async deleteRecipe ({ commit, rootState }, item) {
      const { data } = await api.recipes.deleteById(rootState.auth.current_farm, item.id)
      commit('REMOVE_RECIPE', item)
      return data
    }
  }
}

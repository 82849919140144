<template lang="pug">
  .v-info(
    :class="classes"
    v-click-outside="hideInfo")
    button.v-info__i(
      @click="toggled = !toggled") i
    transition(name="fadex")
      .v-info__content(
        v-if="toggled")
        v-card(
          elevated
          overflow-hidden)
          button.v-info__i i
          .v-info__content-inner
            slot

</template>

<script>
export default {
  name: 'VInfo',

  props: {
    right: Boolean,
    dark: Boolean
  },

  data: () => ({
    toggled: false
  }),

  computed: {
    classes () {
      return {
        'v-info--dark': this.dark
      }
    }
  },

  methods: {
    hideInfo () {
      this.toggled = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  $icon-offset: 8px;

  .v-info {
    display: inline-block;
    position: relative;

    &--dark {

      .v-info__i {
        color: $color-black;
      }
    }

    &__i {
      cursor: pointer;
      color: $color-green-light-1;
      font-family: Georgia;
      font-size: 1.6rem;
    }

    &__content {
      top: -$icon-offset;
      right: -$icon-offset;
      position: absolute;
      z-index: 100;

      .v-info__i {
        top: $icon-offset;
        right: $icon-offset;
        position: absolute;
        z-index: 100;
      }
    }

    &__content-inner {
      padding-right: 20px;
    }
  }
</style>

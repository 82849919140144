import _ from 'lodash'
import api from '@/api'

export default {
  state: {
    ingredients: [],
    ingredientsFilters: {},
    ingredientsList: []
  },

  getters: {
    ingredients (state) {
      return state.ingredients
    },

    ingredientsFilters (state) {
      return state.ingredientsFilters
    },

    hasIngredientsFilters (state) {
      return !_.isEmpty(state.ingredientsFilters)
    },

    ingredientsList (state) {
      return state.ingredientsList
    }
  },

  mutations: {
    SET_INGREDIENTS (state, ingredients) {
      state.ingredients = ingredients
    },

    ADD_INGREDIENT (state, ingredient) {
      state.ingredients.unshift(ingredient)
    },

    UPDATE_INGREDIENT (state, ingredient) {
      const index = state.ingredients
        .findIndex(item => item.id === ingredient.id)
      if (index !== -1) {
        const item = state.ingredients[index]
        for (const key in ingredient) {
          item[key] = ingredient[key]
        }
      }
    },

    REMOVE_INGREDIENT (state, id) {
      const index = state.ingredients
        .findIndex(item => item.id === id)
      if (index !== -1) {
        state.ingredients.splice(index, 1)
      }
    },

    SET_INGREDIENTS_FILTERS (state, filters) {
      state.ingredientsFilters = filters
    },

    SET_INGREDIENTS_LIST (state, ingredients) {
      state.ingredientsList = ingredients
    },

    SET_FILTER_VALUE (state, data) {
      const filters = state.ingredientsFilters
      filters[data.key] = data.value
      state.ingredientsFilters = filters
    }
  },

  actions: {
    async fetchIngredients ({ commit, state, rootState }, params = {}) {
      const { data } = await api.ingredients.list(rootState.auth.current_farm, { ...params, ...state.ingredientsFilters })
      commit('SET_INGREDIENTS', data.result.data)
      return data.result
    },

    applyIngredientsFilters ({ commit, rootState }, filters = {}) {
      commit('SET_INGREDIENTS_FILTERS', filters)
    },

    applyFilterValue ({ commit, rootState }, data = {}) {
      commit('SET_FILTER_VALUE', data)
    },

    async fetchIngredientsList ({ commit, rootState }) {
      const { data } = await api.lists.getIngredients(rootState.auth.current_farm)
      commit('SET_INGREDIENTS_LIST', data.result)
      return data.result
    },

    async addIngredient ({ commit, rootState }, payload) {
      const { data, error } = await api.ingredients.create(rootState.auth.current_farm, payload)
      if (!error) {
        commit('ADD_INGREDIENT', data.result)
        return data.result
      }
      return { data, error }
    },

    async updateIngredient ({ commit, rootState }, { id, payload }) {
      const { data, error } = await api.ingredients.updateById(rootState.auth.current_farm, id, payload)
      if (!error) {
        commit('UPDATE_INGREDIENT', data.result)
        return data.result
      }
      return { data, error }
    },

    async deleteIngredient ({ commit, rootState }, { id }) {
      const { data, error } = await api.ingredients.deleteById(rootState.auth.current_farm, id)
      if (!error) {
        commit('REMOVE_INGREDIENT', id)
        return data.result
      }
      return { data: data.data, error }
    }
  }
}

import { render, staticRenderFns } from "./VImageSkeleton.vue?vue&type=template&id=e827a344&scoped=true&lang=pug&functional=true"
import script from "./VImageSkeleton.vue?vue&type=script&lang=js"
export * from "./VImageSkeleton.vue?vue&type=script&lang=js"
import style0 from "./VImageSkeleton.vue?vue&type=style&index=0&id=e827a344&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "e827a344",
  null
  
)

export default component.exports
import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faBars,
  faFilter,
  faKey,
  faPlus,
  faPencilAlt,
  faCheck,
  faChevronCircleDown,
  faInfo,
  faEye,
  faEyeSlash,
  faEllipsisV,
  faSignOutAlt,
  faInfoCircle,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faCog,
  faFileDownload,
  faQrcode,
  faDownload,
  faArrowDown,
  faArrowUp,
  faWindowClose,
  faStopCircle,
  faTractor
} from '@fortawesome/free-solid-svg-icons'

import {
  faCalendarAlt
} from '@fortawesome/free-regular-svg-icons'

library.add(faBars)
library.add(faFilter)
library.add(faKey)
library.add(faPlus)
library.add(faPencilAlt)
library.add(faCheck)
library.add(faChevronCircleDown)
library.add(faInfo)
library.add(faEye)
library.add(faEyeSlash)
library.add(faEllipsisV)
library.add(faSignOutAlt)
library.add(faInfoCircle)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faChevronDown)
library.add(faCalendarAlt)
library.add(faCog)
library.add(faFileDownload)
library.add(faQrcode)
library.add(faDownload)
library.add(faWindowClose)
library.add(faArrowUp)
library.add(faArrowDown)
library.add(faStopCircle)
library.add(faTractor)

Vue.component('font-awesome-icon', FontAwesomeIcon)

<template lang="pug">
  .v-input
    input.v-input__field(
      type="text"
      :value="value"
      :disabled="disabled"
      :autocomplete="autocomplete"
      @input="onInput"
      required)
    label.v-input__label {{ label }}
    .v-input__bar
    .v-input__icon.v-input__icon--right
      slot(name="icon-right")
</template>

<script>
export default {
  name: 'VInput',

  props: {
    label: String,
    value: [String, Number],
    disabled: Boolean,
    autocomplete: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .v-input {
    position: relative;
    height: 4rem;

    &__field {
      width: 100%;
      font-size: 1.5rem;
      padding: 1rem 0;
      display: block;
      border: none;
      background: transparent;
      border-bottom: .1rem solid $color-gray-light-2;

      &:focus {
        outline: none;
      }

      &:focus ~ .v-input__icon {
        color: $color-black;
      }

      &:disabled {
        color: $color-gray;
      }

      &:disabled ~ label,
      &:focus ~ label,
      &:valid ~ label {
        top: -.8rem;
        font-size: 1.3rem;
        color: $color-gray;
      }

      &:focus ~ .v-input__bar:before,
      &:focus ~ .v-input__bar:after {
        width: 50%;
      }
    }

    &__label {
      font-size: 1.5rem;
      position: absolute;
      pointer-events: none;
      top: 1rem;
      transition: 0.2s ease all;
    }

    &__bar {
      position: relative;

      &:before,
      &:after {
        content: "";
        height: .2rem;
        width: 0;
        bottom: .1rem;
        position: absolute;
        background: $color-brand;
        transition: 0.2s ease all;
      }

      &:before {
        left: 50%;
      }

      &:after {
        right: 50%;
      }
    }

    &__icon {
      position: absolute;
      top: 0;
      height: 100%;
      width: 3rem;
      font-size: 2rem;
      color: $color-gray;
      display: flex;
      align-items: center;
      justify-content: center;

      &--right {
        right: 0;
      }
    }
  }
</style>

import api from '@/api'

export default {
  state: {
    farms: []
  },
  getters: {
    farms (state) {
      return state.farms
    }
  },
  mutations: {
    SET_FARMS (state, data) {
      state.farms = data
    }
  },
  actions: {
    fetchUserFarms ({ commit }, params = {}) {
      api.farms.list(params).then(response => {
          commit('SET_FARMS', response.data)
        }
      )
    }
  }
}

import { render, staticRenderFns } from "./VChevron.vue?vue&type=template&id=493cedb8&scoped=true&lang=pug&functional=true"
import script from "./VChevron.vue?vue&type=script&lang=js"
export * from "./VChevron.vue?vue&type=script&lang=js"
import style0 from "./VChevron.vue?vue&type=style&index=0&id=493cedb8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "493cedb8",
  null
  
)

export default component.exports
import axios from 'axios'
import router from '@/router'
import { LANG_VAR_LOCAL_STORAGE_KEY } from '@/util/consts'

const client = axios.create({
  baseURL: process.env.VUE_APP_URL,
  responseType: 'json'
})

export const getToken = () => {
    const user = localStorage.getItem('user')
    if (user) {
      return JSON.parse(user).token
    }
}

export const execute = async (method, resource, data, headers, cancelToken, responseType) => {
  const defaultHeaders = {
    'content-type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${getToken()}`,
    'Accept-Language': localStorage.getItem(LANG_VAR_LOCAL_STORAGE_KEY) || 'uk'
  }

  return client({
    method,
    url: resource,
    data: data,
    headers: headers || defaultHeaders,
    cancelToken: cancelToken,
    responseType: responseType || 'json'
  }).then(resp => {
    return {
      data: resp.data,
      headers: resp.headers
    }
  }).catch(error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem('user')
          router.push('/login')
          return {
            error: error.response.data
          }
      }
      return {
        error: true,
        headers: error.headers,
        data: error.response.data
      }
    }
    return { error }
  })
}

export const addQueryParams = (url, params = {}) => {
  let paramsLine = ''
  for (const key in params) {
    if (params[key] !== null) {
      if (Array.isArray(params[key])) {
        for (const item of params[key]) {
          paramsLine += paramsLine ? '&' : '?'
          paramsLine += `${key}[]=${item}`
        }
      } else {
        paramsLine += paramsLine ? '&' : '?'
        paramsLine += key + '=' + encodeURIComponent(params[key])
      }
    }
  }
  return url + paramsLine
}

export const cancelableRequest = (baseExecute) => {
  const baseExecuteFunction = baseExecute
  const CancelToken = axios.CancelToken
  let cancelCallback = null
  return {
    execute: (method, resource, data, headers) => {
      if (cancelCallback) {
        cancelCallback()
        cancelCallback = null
      }
      return baseExecuteFunction(method, resource, data, headers, new CancelToken(function executor (c) {
        cancelCallback = c
      }))
    }
  }
}

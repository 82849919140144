<template lang="pug">
  .v-select(v-click-outside="hideItems")
    .v-select__field(
      :class="{ 'v-select__field--disabled': disabled, 'v-select__field--no-border': isInvisibleBorder }"
      @click="toggle")
      input.v-select__field-input(
        v-focus
        v-if="toggled"
        v-model="search"
        type="text"
        :placeholder="searchPlaceholder"
        @click.stop)
      .v-select__title(
        v-else
        :title="selectTitle"
        :class="{ 'v-select__title--hidden': selectTitle === label }")
        | {{ selectTitle | translate | shortText }}
      label.v-select__label(:class="{ 'v-select__label--active': selectTitle !== label }") {{ label }}
      .v-select__bar
      .v-select__icon
        v-chevron(:value="toggled")

    transition(name="fadex")
      .v-select__items(
        v-if="toggled"
        :class="{ 'v-select__items--fix-height': filteredOptions.length > 4 }")
        vue-scroll
          .item(v-for="item in filteredOptions"
            @click="selectItem(item)"
            :key="item.id")
              | {{ item[itemName] | translate }}
          .empty(v-if="filteredOptions.length === 0")
            | {{ 'messages.no_options' | translate }}

</template>

<script>
import VChevron from '../v-chevron/VChevron'

export default {
  name: 'VSelect',

  components: {
    VChevron
  },

  props: {
    label: String,
    value: [String, Number, Boolean, Object],
    options: {
      type: Array,
      default: () => ([])
    },
    searchPlaceholder: {
      type: String,
      default: '' // Пошук
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    itemName: {
      type: String,
      default: 'name'
    },
    getFullItem: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    isInvisibleBorder: Boolean
  },

  data: () => ({
    search: '',
    toggled: false
  }),

  filters: {
    shortText (text) {
      const maxLength = 18
      return text && text.length > maxLength
        ? (text.substring(0, maxLength) + '...')
        : text
    }
  },

  methods: {
    selectItem (item) {
      this.toggled = !this.toggled
      if (this.getFullItem) {
        this.$emit('input', item)
      } else {
        this.$emit('input', item[this.itemValue])
      }
    },

    toggle () {
      if (!this.disabled) {
        this.toggled = !this.toggled
      }
    },

    hideItems () {
      if (this.toggled) {
        this.toggled = false
      }
    },

    parseValue () {
      if (typeof this.value === 'object') {
        return this.value.id
      }
      return this.value
    }
  },

  computed: {
    selectTitle () {
      if (this.value !== null) {
        const selected = this.options
          .find(item => item[this.itemValue] === this.parseValue())
        if (selected) {
          return selected[this.itemName]
        }
      }
      return this.label
    },

    filteredOptions () {
      return this.options
        .filter(item => item[this.itemName]
          .startsWith('constants')
            ? this.$t(item[this.itemName])
              .includes(this.search)
            : item[this.itemName]
              .includes(this.search))
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .v-select {
    position: relative;

    &__field {
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 0;
      border-bottom: 1px solid rgba(175, 175, 175, .2);

      &--disabled {
        opacity: .5;
      }

      &--no-border {
        border-bottom: none;
      }

      &-input {
        width: 100%;
        border: none;
        font-size: 1.4rem;

        &:focus {
          outline: none;
        }

        &:focus ~ label,
        &:valid ~ label {
          top: -.8rem;
          font-size: 1.3rem;
          color: $color-gray;
        }

        &:disabled ~ label {
          color: $color-gray-light;
        }

        &:focus ~ .v-select__bar:before,
        &:focus ~ .v-select__bar:after {
          width: 50%;
        }
      }
    }

    &__title {
      margin-right: 1.8rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 24rem;
      font-size: 1.5rem;
      user-select: none;

      &--hidden {
        opacity: 0;
      }
    }

    &__label {
      font-size: 1.5rem;
      position: absolute;
      pointer-events: none;
      top: 1rem;
      transition: 0.2s ease all;

      &--active {
        top: -.8rem;
        font-size: 1.3rem;
        color: $color-gray;
      }
    }

    &__bar {

      &:before,
      &:after {
        content: "";
        height: .2rem;
        width: 0;
        bottom: 0;
        position: absolute;
        background: $color-brand;
        transition: 0.2s ease all;
      }

      &:before {
        left: 50%;
      }

      &:after {
        right: 50%;
      }
    }

    &__items {
      position: absolute;
      z-index: 1000;
      //width: 100%;
      min-height: 4rem;
      background: #fff;
      padding: 1rem 0;
      border-radius: 1rem;
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, .2);

      &--fix-height {
        height: 21rem;
      }

      .item {
        cursor: pointer;
        user-select: none;
        position: relative;
        padding: 1rem;
        width: 100%;
        font-size: 1.5rem;

        &:hover {
          background: rgba(34, 166, 29, 0.4);
        }
      }

      .empty {
        font-size: 1.5rem;
        padding: .5rem 1rem;
      }
    }
  }
</style>

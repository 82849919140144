<template lang="pug">
  .v-input-password
    .v-input-password__wrapper
      input(
        :value="value"
        :type="passwordFieldType"
        :autocomplete="autocomplete"
        @input="onChange"
        required).v-input-password__field
      label.v-input-password__label {{label}}
      .v-input-password__bar
      button(@click="switchVisibility").v-input-password__btn
        span.v-input-password__icon
          font-awesome-icon(
            size="sm"
            :icon="activeField ? 'eye' : 'eye-slash'")
</template>

<script>

export default {
  name: 'VPasswordField',

  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    autocomplete: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    activeField: false,
    passwordFieldType: 'password'
  }),

  methods: {
    onChange (event) {
      this.$emit('input', event.target.value)
    },
    switchVisibility () {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      this.activeField = !this.activeField
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/sass/abstracts/_variables.scss';

.v-input-password {
  position: relative;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__label {
    position: absolute;
    pointer-events: none;
    font-size: 1.5rem;
    color: $color-black;
    top: 1rem;
    transition: .3s ease all;
  }

  &__field {
    position: relative;
    display: block;
    padding: 1rem 2.6rem .8rem 0;
    width: 100%;
    border-bottom: .1rem solid $color-gray-light-2;
    font-size: 1.5rem;
    color: $color-black;
    outline: none;
    z-index: 1;
    background: transparent;

    &::placeholder {
      color: inherit;
    }

    &:focus {
      outline: none;
    }

    &:focus ~ label,
    &:valid ~ label {
      top: -.8rem;
      font-size: 1.3rem;
      color: $color-gray;
    }

    &:focus ~ .v-input-password__bar:before,
    &:focus ~ .v-input-password__bar:after {
      width: 50%;
    }
  }

  &__bar {
    position: relative;

    &:before,
    &:after {
      content: "";
      height: .2rem;
      width: 0;
      bottom: .1rem;
      position: absolute;
      background: $color-brand;
      transition: 0.2s ease all;
    }

    &:before {
      left: 50%;
    }

    &:after {
      right: 50%;
    }
  }

  &__btn {
    position: absolute;
    outline: none;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.8rem;
    height: 1.8rem;
    z-index: 1;
  }
}
</style>

<template lang="pug" >
  tbody.table__body(:class="classes")
    template(v-if="!loading")
      tr.table__body--empty(
        v-if="empty || noPermissions"
        :class="{'table__body--grid-item': outsideTable}")
        td(:colspan="cols")
          h2 {{ tableText }}
      slot(v-else)
    template(v-else)
      tr.table__row.table__row--body(
        v-for="r in 7")
        td.table__cell(
          v-for="i in cols")
          .skeleton

</template>

<script>
export default {
  name: 'VTableBody',

  props: {
    cols: {
      type: Number,
      default: 1
    },
    gap: Boolean,
    outsideTable: Boolean,
    empty: Boolean,
    noPermissions: Boolean,
    noResult: Boolean,
    loading: Boolean
  },

  computed: {
    classes () {
      return {
        'table__body--grid': this.outsideTable,
        'table__body--gap': this.gap
      }
    },

    tableText () {
      if (this.noPermissions) {
        return this.$t('messages.no_permissions')
      } else if (this.noResult) {
        return this.$t('messages.no_filter_result')
      }
      return this.$t('messages.no_result')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';
  @import '@/sass/abstracts/_mixins.scss';

  .table__body--empty {
    height: 38.5rem;
    background-color: $color-white;
    border-top: .1rem solid $table-border-color;

    td {
      text-align: center;
    }

    h2 {
      color: $color-gray-light-1;
      font-weight: 600;
      margin-bottom: 2.5rem;
    }
  }

  .table__body--grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .table__body--grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table__body--gap {
    padding-bottom: 2rem;
  }

  .table__body {

    .skeleton {
      width: 100%;
      height: 2rem;
      border-radius: .8rem;
      @include skeleton;
    }
  }
</style>

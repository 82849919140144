import api from '@/api'

export default {
  state: {
    providers: [],
    providersList: [],
    buyersList: []
  },

  getters: {
    providers (state) {
      return state.providers
    },

    providersList (state) {
      return state.providersList
    },

    buyersList (state) {
      return state.buyersList
    }
  },

  mutations: {
    SET_PROVIDERS (state, providers) {
      state.providers = providers
    },

    ADD_PROVIDER (state, provider) {
      state.providers.unshift(provider)
    },

    UPDATE_PROVIDER (state, provider) {
      const index = state.providers
        .findIndex(item => item.id === provider.id)
      if (index !== -1) {
        const item = state.providers[index]
        for (const key in provider) {
          item[key] = provider[key]
        }
      }
    },

    REMOVE_PROVIDER (state, id) {
      const index = state.providers
        .findIndex(item => item.id === id)
      if (index !== -1) {
        state.providers.splice(index, 1)
      }
    },

    SET_PROVIDERS_LIST (state, providers) {
      state.providersList = providers
    },

    SET_BUYERS_LIST (state, buyers) {
      state.buyersList = buyers
    }
  },

  actions: {
    async fetchProviders ({ commit, rootState }, params = {}) {
      const { data } = await api.providers.list(rootState.auth.current_farm, params)
      commit('SET_PROVIDERS', data.result.data)
      return data.result
    },

    async fetchProvidersList ({ commit, rootState }) {
      const { data } = await api.lists.getProviders(rootState.auth.current_farm)
      commit('SET_PROVIDERS_LIST', data.result)
      return data.result
    },

    async fetchBuyersList ({ commit, rootState }) {
      const { data } = await api.lists.getBuyers(rootState.auth.current_farm)
      commit('SET_BUYERS_LIST', data.result)
      return data.result
    },

    async addProvider ({ commit, rootState }, payload) {
      const { data, error } = await api.providers.create(rootState.auth.current_farm, payload)
      if (!error) {
        commit('ADD_PROVIDER', data.result)
        return data.result
      }
      return { data: data.data, error }
    },

    async updateProvider ({ commit, rootState }, { id, payload }) {
      const { data, error } = await api.providers.updateById(rootState.auth.current_farm, id, payload)
      if (!error) {
        commit('UPDATE_PROVIDER', data.result)
        return data.result
      }
      return { data: data.data, error }
    },

    async deleteProvider ({ commit, rootState }, { id }) {
      const { data, error } = await api.providers.deleteById(rootState.auth.current_farm, id)
      if (!error) {
        commit('REMOVE_PROVIDER', id)
        return data.result
      }
      return { data: data, error }
    }
  }
}

import Vue from 'vue'
import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

Vue.directive('permission-hide', function (el, permission, vNode) {
  const authPermission = vNode.context.$store.state.auth.user.user.permissions
  if (!authPermission.some(authPermission => authPermission.name === permission.value)) {
    el.style.display = 'none'
  }
})

Vue.directive('permission-disable', function (el, permission, vNode) {
  const authPermission = vNode.context.$store.state.auth.user.user.permissions
  if (!authPermission.some(authPermission => authPermission.name === permission.value)) {
    el.disabled = true
    el.style.pointerEvents = 'none'
  }
})

import api from '@/api'
import roles from '@/util/roles'

export default {
  state: {
    user: null,
    current_farm: null
  },

  getters: {
    user (state) {
      return state.user
    },
    currentFarm (state) {
      return state.current_farm
    }
  },

  mutations: {
    SET_USER_DATA (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
    },

    CLEAR_USER_DATA (state) {
      localStorage.removeItem('user')
      localStorage.removeItem('current_farm')
      state.user = null
      state.current_farm = null
      location.reload()
    },

    SET_CURRENT_FARM (state, farmID) {
      localStorage.setItem('current_farm', farmID)
      state.current_farm = farmID
    }
  },

  actions: {
    async login ({ commit }, payload) {
      const { data, error } = await api.auth.login(payload)
      if (!error && data.user.role !== roles.employee) {
        commit('SET_USER_DATA', data)
      }
      return { data, error }
    },

    logout ({ commit }) {
      commit('CLEAR_USER_DATA')
    },

    setCurrentFarm ({ commit }, farmID) {
      commit('SET_CURRENT_FARM', farmID)
    }
  }
}

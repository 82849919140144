<template lang="pug">
.v-upload
  input.v-upload__input(
    ref="fileInput"
    type="file"
    :accept="accept"
    @change="handleFileChange")

  .v-upload__btn
    v-btn(
      @click="$refs.fileInput.click()"
      block
      gray
      outline)
      v-icon(
        position-left
        font-size="1.3"
        icon="qrcode")
      | {{ 'actions.add_qr' | translate }}
</template>

<script>
export default {
  name: 'VUpload',

  props: {
    value: {
      type: String,
      default: ''
    },

    accept: {
      type: String,
      default: 'image/*'
    }
  },

  methods: {
    handleFileChange (e) {
      if (e.target.files[0]) {
        const selectedFile = e.target.files[0]
        this.createBase64Image(selectedFile)
      }
    },

    createBase64Image (fileObject) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.$emit('input', e.target.result)
      }
      reader.readAsDataURL(fileObject)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-upload {
  position: relative;

  &__input {
    position: absolute;
    left: 0;
    opacity: 0;
    pointer-events: none;
    max-width: 0;
    width: 0;
  }
}
</style>

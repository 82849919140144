export default {
  admin: 'Administrator',
  support: 'Support',
  consultant: 'Consultant',
  customer: 'Customer',
  director: 'Director',
  technologist: 'Technologist',
  accounter: 'Accounter',
  employee: 'Employee'
}

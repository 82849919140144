export const USER_TYPES = {
  Support: 'constants.user_types.support',
  Consultant: 'constants.user_types.consultant',
  Customer: 'constants.user_types.customer',
  Director: 'constants.user_types.director',
  Technologist: 'constants.user_types.technologist',
  Accounter: 'constants.user_types.accounter',
  Employee: 'constants.user_types.employee'
}

export const COLORS = {
  red: '#FF0000',
  green: '#1DA643',
  gray: '#333'
}

export const PLANNING_STATUSES = {
  empty: { name: 'constants.planning_statuses.empty', color: '#FF0000' },
  in_progress: { name: 'constants.planning_statuses.in_progress', color: '#5ACB58' },
  wait: { name: 'constants.planning_statuses.wait', color: '#FFC700' },
  done: { name: 'constants.planning_statuses.done', color: '#277FD0' },
  uncompleted: { name: 'constants.planning_statuses.uncompleted', color: '#FFC700' }
  // download: { name: 'download', color: '#CCC' }
}

export const ACTION_TYPES = {
  store: 'constants.action_types.store',
  update: 'constants.action_types.update',
  destroy: 'constants.action_types.destroy'
}

export const PERMISSION_SECTIONS = {
  timetable: 'constants.permission_sections.timetable',
  recipes: 'constants.permission_sections.recipes',
  ingredients: 'constants.permission_sections.ingredients',
  supplies: 'constants.permission_sections.supplies',
  'write-off': 'constants.permission_sections.write_off',
  providers: 'constants.permission_sections.providers',
  users: 'constants.permission_sections.users',
  logs: 'constants.permission_sections.logs',
  reports: 'constants.permission_sections.reports',
  silages: 'constants.permission_sections.silages',
  mixers: 'constants.permission_sections.mixers'
}

export const LANG_VAR_LOCAL_STORAGE_KEY = 'pepalang'

<template lang="pug">
  li.v-list__item(
    :class="classes")
    slot
</template>

<script>
export default {
  name: 'VListItem',

  props: {
    active: Boolean
  },

  computed: {
    classes () {
      return {
        'v-list__item--active': this.active
      }
    }
  }
}
</script>

<style lang="scss">
</style>

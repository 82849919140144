import api from '@/api'

export default {
  state: {
    mixers: []
  },

  getters: {
    mixers (state) {
      return state.mixers
    }
  },

  mutations: {
    SET_MIXERS (state, mixers) {
      state.mixers = mixers
    },

    ADD_MIXER (state, mixer) {
      state.mixers.unshift(mixer)
    },

    UPDATE_MIXER (state, mixer) {
      const index = state.mixers
        .findIndex(item => item.id === mixer.id)
      if (index !== -1) {
        const item = state.mixers[index]
        for (const key in mixer) {
          item[key] = mixer[key]
        }
      }
    },

    REMOVE_MIXER (state, id) {
      const index = state.mixers
        .findIndex(item => item.id === id)
      if (index !== -1) {
        state.mixers.splice(index, 1)
      }
    }
  },

  actions: {
    async fetchMixers ({ commit, rootState }, params = {}) {
      const { data } = await api.mixers.list(rootState.auth.current_farm, params)
      commit('SET_MIXERS', data.result.data)
      return data.result
    },

    async addMixer ({ commit, rootState }, payload) {
      const { data, error } = await api.mixers.create(rootState.auth.current_farm, payload)
      if (!error) {
        commit('ADD_MIXER', data.result)
        return data.result
      }
      return { data: data.data, error }
    },

    async updateMixer ({ commit, rootState }, { id, payload }) {
      const { data, error } = await api.mixers.updateById(rootState.auth.current_farm, id, payload)
      if (!error) {
        commit('UPDATE_MIXER', data.result)
        return data.result
      }
      return { data: data.data, error }
    },

    async deleteMixer ({ commit, rootState }, { id }) {
      const { data, error } = await api.mixers.deleteById(rootState.auth.current_farm, id)
      if (!error) {
        commit('REMOVE_MIXER', id)
        return data.result
      }
      return { data: data.data, error }
    }
  }
}

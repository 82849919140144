<template lang="pug">
  .v-scrollable(
    :style="styles")
    vue-scroll(
      ref="scroll"
      @handle-scroll="onScroll")
      .v-scrollable__inner(ref="content")
        slot
</template>

<script>
import { ResizeObserver } from '@juggle/resize-observer'
import { windowResizeObserver } from '@/util/utils'

export default {
  name: 'VScrollable',

  props: {
    maxHeight: Number,
    minHeight: {
      type: Number,
      default: 40
    },
    bottomOffset: {
      type: Number,
      default: 20
    }
  },

  mounted () {
    setTimeout(() => {
      this.initParams()
    }, 1)
    windowResizeObserver.subscribe(this.onWindowResize)
    this.initContentResizeObserver()
  },

  beforeDestroy () {
    windowResizeObserver.unsubscribe(this.onWindowResize)
    this.resizeObserver.unobserve(this.$refs.content)
  },

  data: () => ({
    resizeObserver: null,
    contentHeight: 0,
    availableSpace: 0
  }),

  methods: {
    initContentResizeObserver () {
      this.resizeObserver = new ResizeObserver(entries => {
        this.onContentResize()
      })
      this.resizeObserver.observe(this.$refs.content)
    },

    onContentResize () {
      this.contentHeight = this.getContentHeight()
    },

    onWindowResize () {
      this.initParams()
    },

    initParams () {
      this.contentHeight = this.getContentHeight()
      this.availableSpace = this.getAvailableSpace()
    },

    getContentHeight () {
      return this.$refs.content &&
        this.$refs.content.clientHeight
    },

    getAvailableSpace () {
      if (this.$refs.content) {
        const topOffset = this.$refs.content.getBoundingClientRect().top
        return window.innerHeight - topOffset - this.bottomOffset
      }
      return 0
    },

    onScroll (vertical, horizontal, nativeEvent) {
      const scrollHeight = nativeEvent.target.scrollHeight - nativeEvent.target.clientHeight
      const scrollTop = vertical.scrollTop
      if (scrollTop >= scrollHeight) {
        this.$emit('scroll-complete', this.$refs.scroll)
      }
    }
  },

  computed: {
    styles () {
      return {
        height: `${this.scrollWrapperHeight}px`
      }
    },

    scrollWrapperHeight () {
      const maxAvailableHeight = this.maxHeight
        ? Math.min(this.maxHeight, this.availableSpace)
        : this.availableSpace
      const heightLimitedByMaxValue = Math.min(this.contentHeight, maxAvailableHeight)
      return Math.max(this.minHeight, heightLimitedByMaxValue)
    }
  }
}
</script>

<style lang="scss">
  .v-scrollable {
    position: relative;

    &__inner {
      &:last-child {
        margin-bottom: 0!important;
      }
    }
  }
</style>

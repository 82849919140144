import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuescroll from 'vuescroll'
import VCalendar from 'v-calendar'
import Paginate from 'vuejs-paginate'
import Toasted from 'vue-toasted'
import VueInputAutowidth from 'vue-input-autowidth'

import VBtn from '@/components/ui/v-btn/VBtn'
import VBtnGroup from '@/components/ui/v-btn-group/VBtnGroup'
import VCard from '@/components/ui/v-card/index.js'
import VChevron from '@/components/ui/v-chevron/VChevron'
import VDatepicker from '@/components/ui/v-datepicker/VDatepicker'
import VDateField from '@/components/ui/v-date-field/VDateField'
import VDialog from '@/components/ui/v-dialog/VDialog'
import VDivider from '@/components/ui/v-divider/VDivider'
import VDropdown from '@/components/ui/v-dropdown/VDropdown'
import VEditable from '@/components/ui/v-editable/VEditable'
import VInfo from '@/components/ui/v-info/VInfo'
import VMultiselect from '@/components/ui/v-multiselect/VMultiselect'
import VNavTabs from '@/components/ui/v-nav-tabs/VNavTabs'
import VMobileTabs from '@/components/ui/v-mobile-tabs/VMobileTabs'
import VSwitch from '@/components/ui/v-switch/VSwitch'
import VTextField from '@/components/ui/v-text-field/VTextField'
import VTimePicker from '@/components/ui/v-time-picker/VTimePicker'
import VPasswordField from '@/components/ui/v-password-field/VPasswordField'
import VSelect from '@/components/ui/v-select/VSelect'
import VScrollable from '@/components/ui/v-scrollable/VScrollable'
import VCheckbox from '@/components/ui/v-checkbox/VCheckbox'
import VPaginate from '@/components/ui/v-paginate/VPaginate'
import VTable from '@/components/ui/v-table/index.js'
import VTooltip from '@/components/ui/v-tooltip/VTooltip'
import VList from '@/components/ui/v-list/index.js'
import VLoader from '@/components/ui/v-loader/VLoader'
import VLoaderButton from '@/components/ui/v-loader-button/VLoaderButton'
import VIcon from '@/components/ui/v-icon/VIcon'
import VUpload from '@/components/ui/v-upload/VUpload'
import VImageSkeleton from '@/components/ui/v-image-skeleton/VImageSkeleton'

// eslint-disable-next-line no-unused-vars
import filters from '@/util/filters'
// eslint-disable-next-line no-unused-vars
import directives from '@/util/directives'
// eslint-disable-next-line no-unused-vars
import faicons from '@/util/fa-icons'
// eslint-disable-next-line no-unused-vars
import polifils from '@/util/polifils'
// eslint-disable-next-line no-unused-vars
import i18n from '@/i18n'

Vue.use(vuescroll, {
  name: 'vue-scroll',
  ops: {
    bar: {
      background: '#333',
      opacity: 0.2
    }
  }
})
Vue.use(VCalendar)
Vue.use(Toasted)
Vue.use(VueInputAutowidth)

const registerComponents = (components) => {
  for (const key in components) {
    Vue.component(key, components[key])
  }
}

registerComponents(VCard)
registerComponents(VTable)
registerComponents(VList)

Vue.component('VBtn', VBtn)
Vue.component('VBtnGroup', VBtnGroup)
Vue.component('VChevron', VChevron)
Vue.component('VDatepicker', VDatepicker)
Vue.component('VDateField', VDateField)
Vue.component('VDialog', VDialog)
Vue.component('VDivider', VDivider)
Vue.component('VDropdown', VDropdown)
Vue.component('VEditable', VEditable)
Vue.component('VInfo', VInfo)
Vue.component('VMultiselect', VMultiselect)
Vue.component('VNavTabs', VNavTabs)
Vue.component('VMobileTabs', VMobileTabs)
Vue.component('VSwitch', VSwitch)
Vue.component('VTextField', VTextField)
Vue.component('VTimePicker', VTimePicker)
Vue.component('VPasswordField', VPasswordField)
Vue.component('VSelect', VSelect)
Vue.component('VScrollable', VScrollable)
Vue.component('VPaginate', VPaginate)
Vue.component('VCheckbox', VCheckbox)
Vue.component('VTooltip', VTooltip)
Vue.component('VLoader', VLoader)
Vue.component('VLoaderButton', VLoaderButton)
Vue.component('VIcon', VIcon)
Vue.component('VUpload', VUpload)
Vue.component('VImageSkeleton', VImageSkeleton)
Vue.component('paginate', Paginate)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  created () {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('SET_USER_DATA', userData)
    }
  },
  render: h => h(App)
}).$mount('#app')

<template lang="pug" functional>
  .v-card__actions(
    :class="{ 'v-card__actions--center': props.center }")
    slot
</template>

<script>
export default {
  name: 'VCardActions',

  props: {
    center: Boolean
  }
}
</script>

<style lang="scss" scoped>
</style>

<template lang="pug" functional>
  .v-chevron(:style="{ transform: `rotate(${props.value ? -180 : 0}deg)` }")
    font-awesome-icon(
      size="sm"
      icon="chevron-down"
      :style="{ color: props.color }")

</template>

<script>
export default {
  name: 'VChevron',

  props: {
    color: {
      type: String,
      default: '#010002'
    },
    size: {
      type: [String, Number],
      default: 15
    },
    value: Boolean
  }
}
</script>

<style lang="scss" scoped>
  .v-chevron {
    font-size: 1.5rem;
    transition: .3s all;
  }
</style>

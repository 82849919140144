<template lang="pug">
  .v-multiselect
    v-dropdown(ref="dropdown")
      template(slot-scope="props")
        .v-multiselect__field
          input.v-multiselect__field-input(
            v-focus
            v-if="props.toggled"
            v-model="search"
            type="text"
            :placeholder="searchPlaceholder"
            @click.stop)
          .v-multiselect__placeholder(
            v-else
            :class="{ 'v-multiselect__placeholder--hidden': selectedLabel === label }") {{ selectedLabel | srtstr }}
          label.v-multiselect__label(:class="{ 'v-multiselect__label--active': selectedLabel !== label }") {{ label }}
          .v-multiselect__bar
          .v-multiselect__chevron
            v-chevron(:value="props.toggled")

      template(slot="content")
        v-card.mb-1(
          elevated
          overflow-hidden)
          v-scrollable(
            :bottom-offset="80")
            ul.v-multiselect__options
              template(v-if="filteredOptions.length")
                v-multiselect-item(
                  v-for="(item, index) in filteredOptions"
                  :key="index"
                  :item="item"
                  :item-name="itemName"
                  :item-value="itemValue"
                  :selected="value"
                  @select="onSelect"
                  @unselect="onUnselect")
              li.v-multiselect__options-info(v-else) {{ 'messages.no_items' | translate }}
        v-card(elevated)
          v-btn(
            block
            @click="$refs.dropdown.closeDropdown()")
            | {{ 'actions.select' | translate }}

</template>

<script>
import VCard from '../v-card/VCard'
import VChevron from '../v-chevron/VChevron'
import VDropdown from '../v-dropdown/VDropdown'
import VScrollable from '../v-scrollable/VScrollable'

import VMultiselectItem from './VMultiselectItem'

export default {
  name: 'VMultiselct',

  components: {
    VCard,
    VChevron,
    VDropdown,
    VScrollable,
    VMultiselectItem
  },

  props: {
    label: String,
    value: Array,
    options: Array,
    searchPlaceholder: {
      type: String,
      default: null // 'Пошук'
    },
    itemName: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: null
    }
  },

  data: () => ({
    search: ''
  }),

  methods: {
    onSelect (item) {
      this.$emit('input', [...this.value, item])
    },

    onUnselect (item) {
      const index = this.value.indexOf(item)
      if (index !== -1) {
        this.value.splice(index, 1)
        this.$emit('input', this.value)
      }
    },

    getItemNameByValue (item) {
      return !this.itemValue
        ? item[this.itemName]
        : this.options
            .find(el => el[this.itemValue] === item)[this.itemName]
    }
  },

  computed: {
    selectedLabel () {
      return this.value.length
        ? this.value
          .map(item => this.getItemNameByValue(item))
          .join(', ')
        : this.label
    },

    filteredOptions () {
      return this.options
        .filter(item => item[this.itemName]
          .includes(this.search))
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';
  @import './_variables.scss';

  .v-multiselect {
    position: relative;

    &__field {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid rgba(175, 175, 175, .2);

      &-input {
        height: 3.7rem;
        width: 100%;
        padding: 1rem 0;
        border: none;
        font-size: 1.4rem;

        &:focus {
          outline: none;
        }

        &:focus ~ label,
        &:valid ~ label {
          top: -.8rem;
          font-size: 1.3rem;
        }

        &:disabled ~ label {
          color: $color-gray-light;
        }

        &:focus ~ .v-multiselect__bar:before,
        &:focus ~ .v-multiselect__bar:after {
          width: 50%;
        }
      }
    }

    &__placeholder {
      user-select: none;
      font-size: 1.5rem;
      padding: 1rem 0;

      &--hidden {
        opacity: 0;
      }
    }

    &__label {
      color: $color-gray;
      font-size: 1.5rem;
      position: absolute;
      pointer-events: none;
      top: 1rem;
      transition: 0.2s ease all;

      &--active {
        font-size: 1.3rem;
        top: -.8rem;
      }
    }

    &__bar {

      &:before,
      &:after {
        content: "";
        height: .2rem;
        width: 0;
        bottom: .1rem;
        position: absolute;
        background: $color-brand;
        transition: 0.2s ease all;
      }

      &:before {
        left: 50%;
      }

      &:after {
        right: 50%;
      }
    }

    &__chevron {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    &__options {
      padding: 1rem 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      background-color: $v-multiselect-list-bg;

      &-info,
      &-item {
        font-size: 1.7rem;
        padding: 1.3rem 1.6rem;
      }

      &-info {
        text-align: center;
      }

      &-item {
        position: relative;
        cursor: pointer;
        user-select: none;
        padding-left: 5rem;

        &:not(:last-child) {
          border-bottom: 1px solid $color-gray-light-3;
        }

        &:hover {
          background-color: $v-multiselect-list-item-hover;
        }

        &::before {
          content: "";
          position: absolute;
          left: 1.3rem;
          top: 50%;
          transform: translateY(-50%);
          height: 2rem;
          width: 2rem;
          border-radius: 4px;
          border: 1px solid $color-gray-light-2;
          background-color: $color-gray-light-3;
        }

        &--selected::after {
          content: "";
          position: absolute;
          left: 1.6rem;
          top: 50%;
          transform: translateY(-50%);
          height: 1rem;
          width: 1.4rem;
          background-image: url('../../../assets/svg/check.svg');
        }
      }
    }
  }
</style>

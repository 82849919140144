import { execute } from '@/api/utils'
import { POST } from '@/api/types'

export default {
  login (payload) {
    return execute(POST, 'login', payload)
  },
  forgotPassword (payload) {
    return execute(POST, 'forgot-password', payload)
  },
  resetPassword (endpoint, payload) {
    return execute(POST, endpoint, payload)
  },
  changePassword (payload) {
    return execute(POST, 'change-password', payload)
  },
  setNewPassword (payload) {
    return execute(POST, 'set-new-password', payload)
  }
}

<template lang="pug">
  v-date-picker(
    v-model="date"
    mode="single"
    color="green"
    :class="classes"
    :locale="$i18n.locale()"
    :attributes="attrs"
    :popover="{ placement: 'bottom', visibility: 'click' } "
    :available-dates="{start: this.dateFrom, end: this.dateTo}")
    .v-datepicker
      .v-datepicker__btn(
        :class="{ 'v-datepicker__btn--disabled': this.disablePrev }"
        @click.stop="prevDate")
        font-awesome-icon(
          size="xs"
          icon="chevron-left")
      .v-datepicker__label {{ dateLabel }}
      .v-datepicker__btn(
        @click.stop="nextDate")
        font-awesome-icon(
          size="xs"
          icon="chevron-right")

</template>

<script>
import { formateDate, formateDateReverse } from '@/util/utils'

export default {
  name: 'VDatepicker',

  props: {
    lockDropdown: Boolean,
    disablePrev: Boolean,
    positionRight: {
      type: Boolean,
      default: false
    },
    dateFrom: Date,
    dateTo: Date
  },

  data: () => ({
    attrs: [
      {
        key: 'today',
        highlight: {
          color: 'green',
          fillMode: 'light'
        },
        dates: new Date()
      }
    ],
    date: new Date()
  }),

  methods: {
    prevDate () {
      if (!this.disablePrev) {
        this.modifyDate(-1)
      }
    },

    nextDate () {
      this.modifyDate(1)
    },

    modifyDate (value) {
      const date = new Date(this.date)
      date.setDate(date.getDate() + value)
      this.date = date
    }
  },

  computed: {
    classes () {
      return {
        'position-right': this.positionRight
      }
    },

    dateLabel () {
      return formateDateReverse(this.date)
    }
  },

  watch: {
    date (value) {
      this.$emit('input', formateDate(value))
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_mixins.scss';
  @import '@/sass/abstracts/_variables.scss';

  .v-datepicker {
    height: 4rem;
    display: flex;
    align-items: center;

    &__btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.2rem;
      width: 2.2rem;
      border-radius: .4rem;
      font-size: 1.6rem;
      background-color: $color-gray-light-2;

      &:hover {
        background-color: darken($color-gray-light-2, 3%);
      }

      &:active {
        color: $color-white;
        background-color: $color-green;
      }

      &--disabled {
        color: $color-gray-light-1;
        background-color: lighten($color-gray-light-2, 3%);
        border: 1px dashed darken($color-gray-light-2, 3%);

        &:hover,
        &:active {
          color: $color-gray-light-1;
          background-color: lighten($color-gray-light-2, 3%);
        }
      }
    }

    &__label {
      cursor: pointer;
      user-select: none;
      font-size: 1.6rem;
      width: 13rem;
      text-align: center;
    }
  }

  .position-right {
    .vc-popover-content-wrapper {
      @include mobile {
        left: 6rem !important;
      }
    }

    .vc-popover-caret.align-center {
      @include mobile {
        left: 25% !important;
      }
    }
  }
</style>

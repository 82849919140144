import auth from './auth'
import companies from './companies'
import farms from './farms'
import housing from './housing'
import ingredients from './ingredients'
import lists from './lists'
import mixers from './mixers'
import permissions from './permissions'
import products from './done-products'
import providers from './providers'
import rations from './rations'
import recipes from './recipes'
import reports from './reports'
import silages from './silages'
import storage from './storage'
import timetables from './timetables'
import users from './users'

export default {
  auth,
  companies,
  farms,
  housing,
  ingredients,
  lists,
  mixers,
  permissions,
  products,
  providers,
  rations,
  recipes,
  reports,
  silages,
  storage,
  timetables,
  users
}

import api from '@/api'

export default {
  state: {
    housings: [],
    housingsList: []
  },

  getters: {
    housings (state) {
      return state.housings
    },

    housingsList (state) {
      return state.housingsList
    }
  },

  mutations: {
    SET_HOUSINGS (state, housings) {
      state.housings = housings
    },

    ADD_HOUSING (state, housing) {
      state.housings.unshift(housing)
    },

    UPDATE_HOUSING (state, housing) {
      const index = state.housings
        .findIndex(item => item.id === housing.id)
      if (index !== -1) {
        const item = state.housings[index]
        for (const key in housing) {
          item[key] = housing[key]
        }
      }
    },

    REMOVE_HOUSING (state, id) {
      const index = state.housings
        .findIndex(item => item.id === id)
      if (index !== -1) {
        state.housings.splice(index, 1)
      }
    },

    SET_HOUSINGS_LIST (state, housings) {
      state.housingsList = housings
    }
  },

  actions: {
    async fetchHousings ({ commit, rootState }, params = {}) {
      const { data } = await api.housing.list(rootState.auth.current_farm, params)
      commit('SET_HOUSINGS', data.result.data)
      return data.result
    },

    async fetchHousingsList ({ commit, rootState }) {
      const { data } = await api.lists.getHousings(rootState.auth.current_farm)
      commit('SET_HOUSINGS_LIST', data.result)
      return data.result
    },

    async addHousing ({ commit, rootState }, payload) {
      const { data, error } = await api.housing.create(rootState.auth.current_farm, payload)
      if (!error) {
        commit('ADD_HOUSING', data.result)
        return data.result
      }
      return { data: data.data, error }
    },

    async updateHousing ({ commit, rootState }, { id, payload }) {
      const { data, error } = await api.housing.updateById(rootState.auth.current_farm, id, payload)
      if (!error) {
        commit('UPDATE_HOUSING', data.result)
        return data.result
      }
      return { data: data.data, error }
    },

    async deleteHousing ({ commit, rootState }, { id }) {
      const { data, error } = await api.housing.deleteById(rootState.auth.current_farm, id)
      if (!error) {
        commit('REMOVE_HOUSING', id)
        return data.result
      }
      return { data, error }
    }
  }
}

import { execute, addQueryParams } from '@/api/utils'
import { GET, PUT, POST, DELETE } from '@/api/types'

export default {
  list (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/ingredients`, params))
  },
  create (farmID, payload) {
    return execute(POST, `farms/${farmID}/ingredients`, payload)
  },
  updateById (farmID, id, payload) {
    return execute(PUT, `farms/${farmID}/ingredients/${id}`, payload)
  },
  getById (farmID, id) {
    return execute(GET, `farms/${farmID}/ingredients/${id}`)
  },
  deleteById (farmID, id) {
    return execute(DELETE, `farms/${farmID}/ingredients/${id}`)
  }
}

<template lang="pug">
  button.v-btn(
    :class="classes"
    :disabled="loading || disabled"
    @click="onClick")
    span.v-btn__content
      slot
    .v-btn__loader(v-if="loading")
      v-loader-button

</template>

<script>
export default {
  name: 'VBtn',

  props: {
    ghost: Boolean,
    icon: Boolean,
    fill: Boolean,
    square: Boolean,
    secondary: Boolean,
    block: Boolean,
    text: Boolean,
    danger: Boolean,
    warning: Boolean,
    gray: Boolean,
    lightGreen: Boolean,
    accent: Boolean,
    loading: Boolean,
    disabled: Boolean,
    outline: Boolean,
    medium: Boolean,
    small: Boolean,
    ignoreGroup: Boolean
  },

  methods: {
    onClick () {
      this.$emit('click')
    }
  },

  computed: {
    classes () {
      return {
        'v-btn--ghost': this.ghost,
        'v-btn--fill': this.fill,
        'v-btn--icon': this.icon,
        'v-btn--square': this.square,
        'v-btn--secondary': this.secondary,
        'v-btn--block': this.block,
        'v-btn--text': this.text,
        'v-btn--danger': this.danger,
        'v-btn--warning': this.warning,
        'v-btn--gray': this.gray,
        'v-btn--light-green': this.lightGreen,
        'v-btn--default': !this.danger,
        'v-btn--loading': this.loading,
        'v-btn--disabled': this.disabled,
        'v-btn--outline': this.outline,
        'v-btn--accent': this.accent,
        'v-btn--medium': this.medium,
        'v-btn--small': this.small,
        'v-btn--ignore-group': this.ignoreGroup
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';
  @import '@/sass/abstracts/_mixins.scss';

  .v-btn {
    position: relative;
    cursor: pointer;
    padding: 1rem 1.5rem;
    font-size: 1.7rem;
    font-weight: 300;
    transition: .3s;
    border-radius: .8rem;
    min-width: 8rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-flex;
      align-items: center;
    }

    &:not(.v-btn--icon) {
      &.v-btn--small {
        padding: .8rem 1.2rem;
        font-size: 1.3rem;
      }
    }

    &:focus {
      outline: none;
    }

    &__loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--default {
      color: $color-white;
      background-color: $color-green;
    }

    &:hover {
      background-color: lighten($color-green, 3%);
    }

    &:active {
      background-color: darken($color-green, 1%);
    }

    &--medium {
      padding: 1rem 2rem;
    }

    &--block {
      width: 100%;
    }

    &--fill {
      &:hover {
        background-color: transparent!important;
      }

      &:active {
        background-color: transparent!important;
      }

      &.v-btn--light-green {
        &:hover {
          fill: $color-green-light-1;
        }
        &:active {
          fill: $color-green-light-1;
        }
      }
    }

    &--icon {
      height: 4rem;
      min-width: 4rem;
      padding: 0;
      border-radius: 50%;

      &:active {
        background-color: transparent;
      }

      &.v-btn--small {
        height: 3.2rem;
        min-width: 3.2rem;
      }
    }

    &--square {
      height: 3rem;
      min-width: 3rem;
      padding: 0;
      border-radius: .5rem;
    }

    &--secondary {
      color: $color-green;
      background-color: rgba($color-green, 0.2);

      @include mobile{
        display: none;
      }

      &:hover {
        background-color: rgba($color-green, 0.3);
      }
    }

    &--disabled {
      color: $color-gray !important;
      cursor: not-allowed;

      &:not(.v-btn--icon) {
        background-color: $color-gray-light-2 !important;
      }

      &.v-btn--icon {
        opacity: .3;
      }
    }

    &--loading {
      span {
        visibility: hidden;
      }
    }

    &--danger {
      color: $color-white;
      font-weight: 400;
      background-color: $color-red;

      &:hover {
        background-color: lighten($color-red, 10%);
      }
    }

    &--warning {
      color: $color-white;
      font-weight: 400;
      background-color: $color-yellow;

      &:hover {
        background-color: lighten($color-yellow, 10%);
      }
    }

    &--gray {
      color: $color-gray;
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }

    &--text,
    &--icon {
      color: $color-black;
      font-weight: 400;
      background-color: transparent;

      &:hover {
        background-color: rgba($color-black, 0.07);
      }

      &:active {
        background-color: rgba($color-black, 0.1);
      }

      &.v-btn--danger {
        color: $color-red;

        &:hover {
          background-color: rgba($color-red, 0.05);
        }

        &:active {
          background-color: rgba($color-red, 0.1);
        }
      }
    }

    &--ghost {
      padding: 0;
      font-weight: 400;
      color: $color-black;
      justify-content: flex-start;
      background-color: transparent;

      &:hover {
        color: $color-green;
        background-color: transparent!important;
      }

      &:active {
        color: darken($color-green, 5);
        background-color: transparent!important;
      }
    }

    &--outline {
      font-weight: 400;
      background-color: transparent;

      &.v-btn--gray {
        border: .1rem  solid $color-gray-light-2;
        font-size: 1.4rem;
        color: $color-gray;

        &:hover {
          background-color: rgba($color-gray, .1);
        }
      }

      &.v-btn--accent {
        border: .1rem solid $color-green;
        color: $color-green;

        &:hover {
          background-color: rgba($color-green-light-2, .2);
        }
      }
    }
  }

  button.v-btn.v-btn--ignore-group {
    &:first-child,
    &:last-child {
      border-top-left-radius: .8rem !important;
      border-top-right-radius: .8rem !important;
      border-bottom-left-radius: .8rem !important;
      border-bottom-right-radius: .8rem !important;
    }
  }
</style>

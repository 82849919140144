<template lang="pug">
  .v-tooltip(
    v-if="isTooltipVisible"
    v-click-outside="closeTooltip"
  )
    .v-tooltip__wrapper
      .v-tooltip__header
        .v-tooltip__icon-wrapper
          p.v-tooltip__icon.info-tooltip i
        .v-tooltip__title-wrapper
          p.v-tooltip__title {{ title }}
          p.v-tooltip__title(
            v-for="(header, index) in headers"
            :key="index"
          ) {{ header }}
      .v-tooltip__body
        ul.v-tooltip__list
          li.v-tooltip__item(
            v-for="(item, index) in list"
            :key="index"
          )
            p.v-tooltip__text {{ item.ingredient_name }}
            p.v-tooltip__text {{ item.rate + '%' }}
</template>

<script>
export default {
  name: 'VTooltip',

  props: {
    title: {
      type: String,
      default: ''
    },

    headers: {
      type: Array,
      default () {
        return []
      }
    },
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data: () => ({
    isTooltipVisible: true
  }),

  methods: {
    closeTooltip () {
      this.isTooltipVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/sass/abstracts/_variables.scss";
  @import "./_variables.scss";

  .v-tooltip {
    padding: .5rem 2rem 1.3rem .5rem;
    background-color: $color-white;
    border-radius: $v-tooltip-border-radius;
    box-shadow: $v-tooltip-box-shadow;

    &--small {
      max-width: 25rem;
      padding: 1.6rem 1.4rem 1.6rem 1.1rem;

      .v-tooltip {

        &__header {
          align-items: start;
          margin-bottom: 0;
        }

        &__title-wrapper {
          flex-direction: column;
        }

        &__title {
          margin-right: 0;
        }
      }
    }

    &--medium {
      padding: 1.3rem 1.5rem 1.3rem 1rem;

      .v-tooltip {

        &__wrapper {
          min-width: 26rem;
        }

        &__header {
          flex-direction: row-reverse;
          margin-bottom: 0;
        }

        &__icon-wrapper {
          margin-left: 3rem;
        }

        &__icon {
          margin-right: 0;
        }

        &__title-wrapper {
          flex-direction: column;
        }

        &__title {
          margin-right: 0;
        }
      }
    }

    &--tiny {
      padding: 1.3rem 1.5rem 1.3rem 1rem;

      .v-tooltip {

        &__wrapper {
          max-width: 23rem;
        }

        &__header {
          flex-direction: row-reverse;
          margin-bottom: 0;
        }

        &__icon-wrapper {
          align-self: flex-start;
          margin-left: 1rem;
        }

        &__icon {
          margin-right: 0;
        }

        &__title-wrapper {
          flex-direction: column;
        }

        &__title {
          margin-right: 0;
        }
      }
    }

    &__header {
      margin-bottom: 1.1rem;
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1.7rem;
    }

    &__icon {
      margin-right: 1rem;
    }

    &__title-wrapper {
      display: flex;
    }

    &__title {
      margin-right: 7rem;

      &:nth-child(1) {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
        margin-left: auto;
      }
    }

    &__list {
      padding-left: 2.8rem;
    }

    &__item {
      margin-bottom: 1.3rem;
      display: flex;
      justify-content: space-between;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__text {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }
</style>

import { execute, addQueryParams } from '@/api/utils'
import { GET } from '@/api/types'

export default {
  getGroups (farmID) {
    return execute(GET, `farms/${farmID}/lists/groups`)
  },
  getHousings (farmID) {
    return execute(GET, `farms/${farmID}/lists/housings`)
  },
  getSilages (farmID) {
    return execute(GET, `farms/${farmID}/lists/silages`)
  },
  getIngredients (farmID) {
    return execute(GET, `farms/${farmID}/lists/ingredients`)
  },
  getProviders (farmID) {
    return execute(GET, `farms/${farmID}/lists/providers`)
  },
  getBuyers (farmID) {
    return execute(GET, `farms/${farmID}/lists/buyers`)
  },
  getRations (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/lists/rations`, params))
  },
  getRecipes (farmID, params = {}) {
    return execute(GET, addQueryParams(`farms/${farmID}/lists/recipes`, params))
  }
}
